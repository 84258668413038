import {
  FormFieldCustom
} from '../Form';
import RawInputRelatedListBranches from '../Form/RawInputRelatedListBranches';

export default {
  Render(props) {
    const {
      values,
      fieldName,
      taxonomyType,
      overrideParams = {},
      fieldsRequired,
      isFilter
    } = props;
    const _taxonomyType = taxonomyType?.data || taxonomyType;
    const title = _taxonomyType?.name;
    const _fieldName = fieldName || _taxonomyType?.nameSlug;
    let param = { ..._taxonomyType?.param, ...overrideParams };
    const style = param?.style;
    param.disabled = isFilter ? false : _taxonomyType?.readOnly;
    if (props.paramsByFieldSlug && props.paramsByFieldSlug[_fieldName]) {
      param = {...param, ...props.paramsByFieldSlug[_fieldName]};
    }
    
    return (
      <FormFieldCustom
        {...param}
        {...style}
        {...props}
        name={_fieldName}
        title={title}
        placeholder={title}
        fieldsRequired={fieldsRequired}
        values={values}
        Render={RawInputRelatedListBranches}
        entitySlug={param?.entityNameSlug || props?.entitySlug} // retrocompatible
      />
    );
  },

  RenderFilter: (props) => (null),
  RenderFilterParam: (props) => (null),
  RenderFilterPreview: ({ values }) => (null),
  RenderInputParams: ({ values, param }) => (null),
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),
  RenderStyleParams: ({ values }) => (null),
  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => (null),
  getDefaultTaxonomyTypeDesign: () => ('default')
}
