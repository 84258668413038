import { useEffect, useState } from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButton,
  IonContent,
  IonIcon
} from '@ionic/react';
import { restaurant, close } from 'ionicons/icons'; // Se importa el ícono "close"
import slugify from "slugify";
import _ from "lodash";
import Model from "../../libs/ModelClass";
import { useAsyncMemo } from "use-async-memo";
import { getImageURL } from "../../libs/utils";

// Se extiende el modelo para la colección 'usersProfiles'
const UserModel = Model.extend('usersProfiles');
const InstitutionModel = Model.extend('institutions');

export const UserSelect = ({
  selectedId,
  selectedUser,
  setSelectedUser,
  title = "Selecciona tu usuario",
  onSelected  // Función que se ejecuta cuando se selecciona un usuario
}) => {
  const [userName, setUserName] = useState("");
  const [userList, setUserList] = useState([]);

  const institutionsDocsByIds = useAsyncMemo(async () => {
    const allDocs = await InstitutionModel.getAll();
    // Filtrar los documentos que no estén marcados como eliminados
    const filteredDocs = allDocs.filter(doc => !doc.data.deleted);
    // Reducir el array a un objeto donde las claves sean los ids de cada documento
    const docsByIds = filteredDocs.reduce((acc, doc) => {
      acc[doc.id] = doc;
      return acc;
    }, {});
    return docsByIds;
  }, []);  

  useEffect(() => {
    // Si aún no se han cargado los usuarios, se invoca la función para obtenerlos
    if (!userList?.length) {
      getAllDocs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllDocs = async () => {
    if (userList.length) return;
    const allDocs = await UserModel.getAll();
    let filteredDocs = [];
    allDocs.forEach((doc) => {
      // Se filtran los documentos que no estén marcados como eliminados y que estén disponibles
      if (!doc.data.deleted) {
        // Se agrega una propiedad para facilitar el ordenamiento (slug del nombre completo)
        doc.data.nameSlug = slugify(`${_.trim(doc.data.firstName)} ${_.trim(doc.data.lastName)}`, { lower: true, replacement: ' ' });
        filteredDocs.push(doc);
      }
    });
    // Se ordena la lista por el slug del nombre
    filteredDocs = _.sortBy(filteredDocs, ['data', 'nameSlug']);
    // Si se pasó un selectedId, se marca el usuario seleccionado
    if (selectedId) {
      const selectedDoc = filteredDocs.find(doc => doc.id === selectedId);
      setSelectedUser(selectedDoc);
    }
    setUserList(filteredDocs);
  };

  const setNameValue = (e) => {
    setUserName(e.target.value);
  };

  // Función de filtrado que compara la búsqueda con firstName, lastName, phone y email
  const filterList = () => {
    if (!userName) return userList;
    const searchTerm = userName.toLowerCase();
    return userList.filter(doc => {
      const { firstName, lastName, phone, email } = doc.data;
      return (
        (firstName && firstName.toLowerCase().includes(searchTerm)) ||
        (lastName && lastName.toLowerCase().includes(searchTerm)) ||
        (phone && phone.toLowerCase().includes(searchTerm)) ||
        (email && email.toLowerCase().includes(searchTerm))
      );
    });
  };

  // Componente para mostrar la tarjeta de cada usuario
  const Card = ({ className, selectedUser, item, onClear }) => (
    <div
      className={`relative flex flex-row items-stretch w-full ${
        selectedUser?.id === item.id ? 'border border-brand-red' : 'border-b border-gray-200'
      } ${className}`}
    >
      {/* Si este item es el seleccionado, se muestra la cruz para deseleccionarlo */}
      {selectedUser?.id === item.id && (
        <IonButton
          onClick={(e) => {
            e.stopPropagation(); // Evita que se dispare el onClick del botón padre
            onClear();
          }}
          fill="clear"
          color="danger"
          style={{ position: 'absolute', top: 0, right: 0 }}
          size="small"
        >
          <IonIcon icon={close} />
        </IonButton>
      )}
      {/* Imagen de la institución del usuario */}
      <div className="w-[80px] h-[75px]">
        {institutionsDocsByIds?.[item.data.institutionId]?.data.images[0] ? (
          <img
            src={getImageURL(institutionsDocsByIds[item.data.institutionId]?.data.images[0], 'xs')}
            alt={institutionsDocsByIds[item?.data?.institutionId]?.data.name}
            className="h-full w-full object-cover"
          />
        ) : null}
      </div>
      {/* Datos del usuario */}
      <div className="px-1.5 py-2.5 text-left leading-tight flex-1">
        <div
          className={`mb-0.5 text-sm font-semibold ${
            selectedUser?.id === item.id ? 'text-brand-red' : 'text-black'
          }`}
        >
          {item?.data?.name} | {item?.data?.firstName} {item?.data?.lastName}
        </div>
        {/* Puedes ajustar los siguientes campos según la información disponible en 'usersProfiles' */}
        <div className="text-xs text-gray-500">{item?.data?.phone} | {item?.data?.email}</div>
        <div className="text-xs text-gray-500">{institutionsDocsByIds?.[item?.data?.institutionId]?.data?.name}</div>
      </div>
    </div>
  );

  return (
    <div className="">
      {/* Campo de búsqueda */}
      <div className="mb-4">
        <h3 className="mb-2 text-sm font-semibold">{title}</h3>
        <input
          type="text"
          value={userName}
          onChange={setNameValue}
          className="shadow appearance-none text-center border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Buscar por nombre, teléfono o email"
        />
      </div>
      {/* Muestra la tarjeta del usuario seleccionado, si existe */}
      {selectedUser ? (
        <Card
          className="mb-4 rounded-md overflow-hidden"
          selectedUser={selectedUser}
          item={selectedUser}
          onClear={() => setSelectedUser(null)}
        />
      ) : null}
      {/* Lista de usuarios filtrados */}
      <div className="h-[40vh] overflow-y-scroll rounded-md border border-gray-300">
        <div className="flex flex-col">
          {filterList().map(item => (
            <button
              key={item.id}
              onClick={() => {
                setSelectedUser(item);
                if (onSelected) onSelected(item);
              }}
            >
              <Card
                selectedUser={selectedUser}
                item={item}
                onClear={() => setSelectedUser(null)}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export const ModalUserSelect = ({
  isOpen,           // Booleano para controlar si el modal está abierto
  onClose,          // Función para cerrar el modal
  selectedUser,     // Usuario seleccionado (opcional)
  setSelectedUser,  // Función para actualizar el usuario seleccionado
  onSelected,       // Función que se invoca al seleccionar un usuario
  onDoAction,    // Función que se invoca al ir a armar menú
  ActionComponent,
  actionLabel,
  title = "Selecciona un usuario"  // Título que se mostrará en el header del modal
}) => {
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          {/* Botón para ir a armar menú */}
          {selectedUser ? (<>
            {ActionComponent ? (
              <ActionComponent />
            ) : (
              <IonButton slot="end" onClick={onDoAction} fill="solid" color="primary" shape="round">
                {actionLabel}
              </IonButton>
            )}
          </>) : null}
          {/* Botón para cerrar el modal */}
          <IonButton slot="end" onClick={onClose} fill="clear" color="medium">
            Cerrar
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <UserSelect
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          title={title}
          onSelected={onSelected}
        />
      </IonContent>
    </IonModal>
  );
};
