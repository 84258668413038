import { useState, useEffect, useRef } from "react";
import { numberFormat } from "../../libs/utils";
import printJS from "print-js";
import BtnLoading from "../../components/ui/BtnLoading";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
  IonInput,
  IonIcon
} from "@ionic/react";
import { IoPrintOutline } from "react-icons/io5";
import { chevronForwardOutline, chevronBackOutline, closeOutline } from "ionicons/icons";
import config from "../../config";
import dayjs from "dayjs";

const useContentToPrint = ({ itemsList, selectedItems, institutionsById, date }) => {
  const doPrintTags = () => {
    const itemsToPrint = itemsList.filter((_, index) => selectedItems[index]);
    if (itemsToPrint.length === 0) {
      alert("No se seleccionaron ítems para imprimir.");
      return;
    }

    printJS({
      printable: "tags-to-print",
      type: "html",
      font_size: '',
      style: `
        @media print {
          #tags-to-print {
            font-family: sans-serif;
            color: black;
            font-size: 6px !important;
          }

          .inline-block {
            display: inline-block;
          }

          .print-item {
            page-break-after: always; /* Ensures each print-item starts on a new page */
          }

          .max-w-full {
            max-width: 100%; /* Full width */
          }

          .flex {
            display: flex;
          }

          .flex-row {
            flex-direction: row;
          }

          .place-content-start {
            place-content: start;
          }

          .items-center {
            align-items: center;
          }

          .gap-4 {
            gap: 1rem; /* Space between flex items */
          }

          .pt-[10px] {
            padding-top: 10px;
          }

          .text-xxs {
            font-size: 0.55rem; /* Extra small text size */
          }

          .text-xs {
            font-size: 0.75rem; /* Extra small text size */
          }

          .text-sm {
            font-size: 0.875rem; /* Small text size */
          }

          .text-base {
            font-size: 1rem; /* Base text size */
          }

          .text-lg {
            font-size: 1.125rem; /* Large text size */
          }

          .text-xl {
            font-size: 1.25rem; /* Extra large text size */
          }

          .text-2xl {
            font-size: 1.5rem; /* Extra extra large text size */
          }

          .font-semibold {
            font-weight: 600; /* Semibold text */
          }

          .mt-2 {
            margin-top: 0.5rem; /* Margin top */
          }

          .mt-1 {
            margin-top: 0.25rem; /* Margin top */
          }

          .mb-[10px] {
            margin-bottom: 10px; /* Margin bottom */
          }

          .pl-1.5 {
            padding-left: 0.375rem; /* Margin top */
          }

          .py-2 {
            padding-top: 0.5rem; /* Padding top and bottom */
            padding-bottom: 0.5rem;
          }

          .max-w-[300px] {
            max-width: 300px; /* Maximum width */
          }

          .w-[110px] {
            width: 110px; /* Fixed width for the image */
          }

          .h-auto {
            height: auto; /* Automatic height */
          }

          .uppercase {
            text-transform: uppercase; /* Uppercase text */
          }

          /* Additional styles */
          .break-after-all {
            page-break-after: always; /* Forces a page break after each print-item */
          }

          @page {
            margin: 4mm;
          }

          @page :left {
            margin-left: 4mm;
          }

          @page :right {
            margin-right: 4mm;
          }
        }
      `
    });
  };

  const rendered = (
    <div id="tags-to-print">
      {Object.keys(selectedItems)
        .filter((index) => selectedItems[index])
        .map((index) => {
          const { price, qty, userDoc, itemDoc, itemExtraDoc } = itemsList[index];
          return (
            <div key={index} className="print-item break-after-all">
              <div className="max-w-full">
                <br />
                {/* header */}
                <div className="pt-[10px] flex flex-row place-content-start items-center gap-4">
                  <div className="">
                    <img src="/logo-full-black.svg" alt="logo" className="w-[110px] h-auto" />
                  </div>
                  <div className="">
                    <div className="uppercase text-xs">Contacto</div>
                    <div className="text-xs">{config.numberString}</div>
                  </div>
                </div>

                {/* content */}
                <div className="mt-1.5 mb-[10px] py-2 flex flex-row place-content-start text-xs">
                  <div className="max-w-[300px]">
                    <div className="">
                      {institutionsById[userDoc.data.institutionId][0]?.data.name} <span className="text-xxs">{dayjs(date).utc().format('YYYY-MM-DD')}</span>
                    </div>
                    {userDoc.data.branchId && institutionsById[userDoc.data.institutionId][0]?.data.branches?.length > 0 && (
                      <div className="text-xxs">
                        Sucursal: {institutionsById[userDoc.data.institutionId][0]?.data.branches.find(branch => branch.id === userDoc.data.branchId)?.name || ''}
                      </div>
                    )}

                    <div className="mt-1.5 font-semibold">
                      {(userDoc.data.firstName || "") + " " + (userDoc.data.lastName || "")} 
                    </div>
                    <div className="text-xxs">CI: {numberFormat(userDoc.data.ci)}</div>

                    <div className="mt-1.5 text-xxs font-semibold">
                      {qty} -{" "}
                      <span>
                        {itemDoc?.data?.name}
                        {itemExtraDoc && (
                          <span>
                            con {itemExtraDoc?.data?.name} como guarnición
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );

  return {
    contentToPrint: rendered,
    contentToPrintTrigger: doPrintTags
  }
};

export const PrintTagsBtn = (props) => {
  let { itemsList, institutionsById, date } = props;

  const [showModal, setShowModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [currentBatch, setCurrentBatch] = useState(0);
  const [batchSize, setBatchSize] = useState(20); // Tamaño de lote por defecto
  const listRef = useRef(null); // Para hacer scroll
  const { contentToPrint, contentToPrintTrigger } = useContentToPrint({ itemsList, selectedItems, institutionsById, date });

  useEffect(() => {
    if (showModal) {
      selectBatch(currentBatch);
    }
  }, [showModal]);

  useEffect(() => {
    // Hacer scroll al primer ítem del lote actual cuando cambia de lote
    scrollToItem(currentBatch * batchSize);
  }, [currentBatch]);

  const handleItemSelect = (index) => {
    setSelectedItems(prev => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const selectedCount = Object.keys(selectedItems).filter((key) => selectedItems[key]).length;

  const clearSelection = () => {
    setSelectedItems({});
  };

  const selectBatch = (batchIndex) => {
    const startIndex = batchIndex * batchSize;
    const endIndex = Math.min(startIndex + batchSize, itemsList.length);

    const updatedSelection = {};
    for (let i = startIndex; i < endIndex; i++) {
      updatedSelection[i] = true;
    }

    setSelectedItems(updatedSelection);
    setCurrentBatch(batchIndex);
    scrollToItem(startIndex); // Hacer scroll al primer ítem del lote
  };

  const selectNextBatch = () => {
    if ((currentBatch + 1) * batchSize >= itemsList.length) return;
    selectBatch(currentBatch + 1);
  };

  const selectPreviousBatch = () => {
    if (currentBatch === 0) return;
    selectBatch(currentBatch - 1);
  };

  const handleBatchSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    if (!isNaN(newSize) && newSize > 0) {
      setBatchSize(newSize);
    }
  };

  // Función para hacer scroll al primer ítem de un lote
  const scrollToItem = (index) => {
    const listItem = document.getElementById(`item-${index}`);
    if (listItem && listRef.current) {
      listRef.current.scrollTo({
        top: listItem.offsetTop - listRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="">
      <BtnLoading
        label="Etiquetas"
        onClick={() => setShowModal(true)}
        icon={<IoPrintOutline className="text-xl" />}
        withLoading
        colorClass="text-white"
        className="px-3 py-1 text-sm !text-black bg-gray-200 shadow-none hover:bg-gray-300"
      />

      {showModal ? (
        <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)} backdropDismiss={false} keyboardClose={false}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Impresión de etiquetas</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowModal(false)}>Cerrar</IonButton>
              </IonButtons>
            </IonToolbar>

            <IonToolbar color="medium">
              <IonButtons slot="start">
                <IonButton onClick={contentToPrintTrigger} fill="outline">
                  Imprimir seleccionados ({selectedCount})
                </IonButton>
                <IonButton onClick={clearSelection}>
                  <IonIcon icon={closeOutline} />
                </IonButton>
                <IonButton onClick={selectPreviousBatch} disabled={currentBatch === 0}>
                  <IonIcon icon={chevronBackOutline} />
                </IonButton>
                <IonButton
                  onClick={selectNextBatch}
                  disabled={(currentBatch + 1) * batchSize >= itemsList.length}
                >
                  <IonIcon icon={chevronForwardOutline} />
                </IonButton>
                <IonInput
                  type="number"
                  value={batchSize}
                  onIonChange={handleBatchSizeChange}
                  placeholder="Tamaño del lote"
                  className="ml-2"
                />
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent ref={listRef}>
            <div className="hidden">
              {contentToPrint}
            </div>

            <div className="p-2">
              {itemsList
                .slice(currentBatch * batchSize, (currentBatch + 1) * batchSize) // Mostrar sólo el lote actual
                .map(({ price, qty, userDoc, itemDoc, itemExtraDoc }, index) => (
                  <div key={index} id={`item-${index}`} className="mb-2 p-2">
                    <IonButton fill="solid" size="small" color="light" onClick={() => handleItemSelect(currentBatch * batchSize + index)}>
                      <input
                        type="checkbox"
                        checked={!!selectedItems[currentBatch * batchSize + index]}
                        className="mr-2"
                      />
                      <span>{currentBatch * batchSize + index + 1}</span>
                    </IonButton>
                    <div className="p-4 border rounded">
                      <div className="">
                        <div className="text-sm">
                          {institutionsById[userDoc.data.institutionId][0]?.data.name}
                        </div>
                        {userDoc.data.branchId && institutionsById[userDoc.data.institutionId][0]?.data.branches?.length > 0 && (
                          <div className="text-xs">
                            Sucursal: {institutionsById[userDoc.data.institutionId][0]?.data.branches.find(branch => branch.id === userDoc.data.branchId)?.name || ''}
                          </div>
                        )}
                        <div className="font-semibold text-base mt-2">
                          {(userDoc.data.firstName || "") + " " + (userDoc.data.lastName || "")}
                        </div>
                        <div className="text-xs">
                          CI: {numberFormat(userDoc.data.ci)}
                        </div>
                      </div>
                      <div className="mt-2 text-sm">
                        {qty} -{" "}
                        <span>
                          {itemDoc?.data?.name}
                          {itemExtraDoc ? (
                            <div>con {itemExtraDoc?.data?.name} como guarnición</div>
                          ) : null}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </IonContent>
        </IonModal>
      ) : null}
    </div>
  );
};
