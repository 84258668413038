import { useCallback, useEffect, useRef, useState } from "react";
import _ from 'lodash';
import dayjs from 'dayjs';
import toast from "react-hot-toast";
import printJS from 'print-js'
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';
import { LayoutAdmin } from "../../components/LayoutAdmin";
import Model from "../../libs/ModelClass";
import BadgeLoading from "../../components/ui/BadgeLoading";
import { InsitutionDataCard } from "../institution/InstitutionDataCard";
import { BillingProductsCardPrintable, BillingProductsCardWithTabs, useBillingDetails } from "./BillingProductsCard";
import BtnLoading from "../../components/ui/BtnLoading";
import ModalAlert from "../../components/ui/ModalAlert";
import { numberFormat, priceFormat } from "../../libs/utils";
import config from "../../config";

const MainModel = Model.extend('customerBillings');
const InstitutionsModel = Model.extend('institutions');
const UserProfilesModel = Model.extend('usersProfiles');

export const CustomerBillingShow = (props) => {
  const {
    docId,
    user,
    isAllowed,
    history
  } = props;
  const [mainDoc, setMainDoc] = useState();
  const [institutionDoc, setInstitutionDoc] = useState();
  const [confirmByUserDoc, setConfirmByUserDoc] = useState();
  const [loading, setLoading] = useState(true);
  const [showPaidConfirmation, setShowPaidConfirmation] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const billingsDetails = useBillingDetails({
    institutionDoc,
    dateFrom: mainDoc?.data?.dateFrom,
    dateTo: mainDoc?.data?.dateTo
  });

  useEffect(() => {
    fetchFilteredDocs();
  }, [docId]);

  const fetchFilteredDocs = async () => {
    setLoading(true);
    let instutionDoc, userDoc;
    let doc = await MainModel.findById(docId);
    setMainDoc(doc);
    if (doc.data?.institutionId) {
      instutionDoc = await InstitutionsModel.findById(doc.data?.institutionId);
      setInstitutionDoc(instutionDoc);
    }
    if (doc.data?.paidConfirmBy) {
      userDoc = await UserProfilesModel.findById(doc.data?.paidConfirmBy);
      setConfirmByUserDoc(userDoc);
    }
    setLoading(false);
  };

  const handlePaid = async () => {
    try {
      mainDoc.data.isPaid = true;
      mainDoc.data.paidDate = dayjs().utc().toISOString();
      mainDoc.data.paidConfirmBy = user.userDoc.id;
      await mainDoc.save();
      setShowPaidConfirmation(false);
    } catch (error) {
      toast.error('Ocurrió un error: ' + error.message);
      console.error(error);
    }
  };

  const doPrint = () => {
    setIsPrinting(true);
    setTimeout(() => {
      printJS({
        printable: 'biilling-to-print',
        type: 'html',
        targetStyles: '*',
        maxWidth: 1000,
        style: `
        .print-only {
          visibility: visible;
          display: block;
        }
        `
      });
      setTimeout(() => {
        setIsPrinting(false);
      }, 500);
    }, 500);
  };

  const doExcel = () => {
    const groupedList = billingsDetails.productsOfRange;
    const groupsSorted = _.sortBy(_.values(groupedList.totalsByUser), (item) => {
      const fullName = (item.userDoc.data.firstName || '') + ' ' + (item.userDoc.data.lastName || '');
      return fullName;
    });

    let result = [];

    _.forEach(groupsSorted, ({ userDoc, total }) => {
      let userItems = groupedList?.itemsInBagsByUser[userDoc.id];
      // Sumar cantidades y precios totales para subtotales
      let subtotalQty = 0;
      let subtotalAmount = 0;
      // Agregar filas de productos
      let userRows = _.map(userItems, ({ price, qty, itemDoc, itemExtraDoc, day }) => {
        subtotalQty += Number(qty);
        subtotalAmount += Number(price) * Number(qty);
        return {
          'Fecha de registro': dayjs(day).tz(config.timezone).format('DD-MM-YYYY HH:mm'),
          'Usuario': (userDoc.data.firstName || '') + ' ' + (userDoc.data.lastName || ''),
          'Cédula': numberFormat(userDoc.data.ci),
          'Precio unitario': Number(price),
          'Cantidad': Number(qty),
          'Precio total': Number(price) * Number(qty),
          'Producto': itemExtraDoc ? (itemDoc?.data?.name + ' con ' + itemExtraDoc?.data?.name) : (itemDoc?.data?.name)
        };
      });
      result = result.concat(userRows);
      // Agregar fila de subtotales
      result.push({
        'Fecha de registro': '', // Dejar vacío o colocar algún texto como "Subtotales"
        'Usuario': 'TOTAL',
        'Cédula': '',
        'Precio unitario': '',
        'Cantidad': subtotalQty,
        'Precio total': subtotalAmount,
        'Producto': ''
      });
    });
    // /* generate worksheet and workbook */
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(result, {
      header: _.keys(result[0])
    });
    XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");
    let fileName = `consumisión ${institutionDoc.data.name} desde ${dayjs(mainDoc.data.dateFrom).format('DD-MM-YYYY')}  hasta ${dayjs(mainDoc.data.dateTo).format('DD-MM-YYYY')}.xlsx`;
    XLSX.writeFile(workbook, fileName, { compression: true });
  };

  const goToEdit = () => {
    history.push(`/admin/customerBilling/form/id/${mainDoc.id}`);
  };

  const confirmedDelete = async () => {
    await mainDoc.delete();
    setShowDeleteAlert(false);
    toast.error('Se ha eliminado el documento');
  };

  return (
    <>
      {(loading || !mainDoc) ? (
        <div className="mt-4 mx-auto w-24">
          <BadgeLoading size="md" />
        </div>
      ) : (<>
        {/* Actions */}
        <div className="flex flex-row place-content-between">
          <div className="">
            {!mainDoc.data.isPaid && isAllowed('customerBillings', ['update']) ? (
              <div className="flex flex-row gap-2">
                <BtnLoading
                  label="Marcar como pagado"
                  onClick={() => setShowPaidConfirmation(true)}
                  withLoading
                  colorClass="text-white"
                  className="inline-block px-3 py-1 text-sm text-white hover:scale-105 bg-brand-red shadow-none"
                />
                <BtnLoading
                  label="Editar"
                  onClick={goToEdit}
                  withLoading
                  colorClass="text-white"
                  className="inline-block px-3 py-1 text-sm !text-black hover:scale-105 bg-brand-yellow/80 shadow-none"
                />
              </div>
            ) : null}
          </div>
          <div className="flex flex-row gap-2">
            {!mainDoc.data.deleted && isAllowed('customerBillings', ['delete']) ? (
              <BtnLoading
                label="Eliminar"
                onClick={() => setShowDeleteAlert(true)}
                withLoading
                colorClass="text-white"
                className="inline-block px-2 py-1 text-sm text-white hover:scale-105 bg-brand-red shadow-none"
              />
            ) : null}
            <BtnLoading
              label="Excel"
              onClick={doExcel}
              withLoading
              colorClass="text-white"
              className="inline-block px-2 py-1 text-sm !text-black hover:scale-105 bg-gray-200 shadow-none"
            />
            <BtnLoading
              label="Imprimir"
              onClick={doPrint}
              withLoading
              colorClass="text-white"
              className="hidden md:inline-block px-2 py-1 text-sm !text-black hover:scale-105 bg-gray-200 shadow-none"
            />
          </div>
        </div>

        {/* Data */}
        <div className="flex flex-row place-content-center">
          <div className="mt-4 p-2 border border-gray-200 rounded w-full max-w-[900px]" id="biilling-to-print">
            {mainDoc.data.deleted ? (
              <div className="mb-2">
                <span className="px-2 py-0.5 rounded-full text-sm text-white bg-red-600">Documento eliminado</span>
              </div>
            ) : null}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              <div>
                <h2 className="mb-1 text-xs font-semibold text-black">Institución</h2>
                <InsitutionDataCard doc={institutionDoc} showLink={isAllowed('institutions', ['list'])} />
              </div>

              <div>
                <h2 className="mb-1 text-xs font-semibold text-black">Periodo</h2>
                <div className="text-sm text-black">
                  Desde: {dayjs(mainDoc?.data?.dateFrom).format('DD-MM-YYYY')}
                  <br />
                  Hasta: {dayjs(mainDoc?.data?.dateTo).format('DD-MM-YYYY')}
                </div>
              </div>

              <div>
                <h2 className="mb-1 text-xs font-semibold">Total de la Institución</h2>
                <div className="text-sm text-lime-600">
                  {billingsDetails?.productsOfRange?.totalTotal ? priceFormat(billingsDetails?.productsOfRange?.totalTotal) : null}
                </div>
              </div>

              <div>
                <h2 className="mb-1 text-xs font-semibold text-black">Estado</h2>
                {mainDoc.data?.isPaid ? (
                  <span className="px-2 py-0.5 rounded-full text-sm text-white bg-lime-600">Pagado</span>
                ) : (
                  <span className="px-2 py-0.5 rounded-full text-sm text-white bg-red-600">Pendiente</span>
                )}
              </div>

              {mainDoc.data?.isPaid ? (<>
                <div>
                  <h2 className="mb-1 text-xs font-semibold text-black">Fecha del pago</h2>
                  <div className="text-sm text-gray-900">{dayjs(mainDoc.data?.paidDate).utc().format('LLL')}</div>
                </div>

                <div>
                  <h2 className="mb-1 text-xs font-semibold text-black">Pago confirmado por</h2>
                  <div className="text-sm text-gray-900">
                    {isAllowed('usersProfiles', ['list']) ? (
                      <Link to={`/admin/entity/usersProfiles/${confirmByUserDoc?.id}`} className="block text-sm text-gray-900 underline">{confirmByUserDoc?.data?.firstName} {confirmByUserDoc?.data?.lastName}</Link>
                    ) : (
                      <div className="text-sm text-gray-900">{confirmByUserDoc?.data?.firstName} {confirmByUserDoc?.data?.lastName}</div>
                    )}
                  </div>
                </div>
              </>) : null}

              <div>
                <h2 className="mb-1 text-xs font-semibold text-black">Id de la órden de cobro</h2>
                <span className="text-sm font-mono text-gray-900">{mainDoc.id}</span>
              </div>

              <div>
                <h2 className="mb-1 text-xs font-semibold text-black">Fecha de creación del registro</h2>
                <div className="text-sm text-gray-900">{dayjs(mainDoc.data?.createdAt).utc().format('LLL')}</div>
              </div>
            </div>

            <div>
              <div className="mt-4 pt-4 border-t border-gray-300"></div>
              {isPrinting? (
                <BillingProductsCardPrintable {...billingsDetails} />
              ) : (
                <BillingProductsCardWithTabs {...billingsDetails} />
              )}
            </div>
          </div>
        </div>
      </>)}
      {showPaidConfirmation && (
        <ModalAlert
          text="¿Confirmar cobro como pagado?"
          onConfirm={handlePaid}
          onCancel={() => setShowPaidConfirmation(false)}
        />
      )}
      {showDeleteAlert && (
          <ModalAlert
            text="¿Estás seguro de que deseas eliminar este elemento?"
            onConfirm={confirmedDelete}
            onCancel={() => setShowDeleteAlert(false)}
          />
        )}
    </>
  );
}

export const RouteCustomerBillingShow = ({ parsedParams, user, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history }) => {
  return (
    <LayoutAdmin
      history={history}
      defaultHref={`/admin/customerBilling/list`}
      title="Órden de Cobro"
      breadcrumbs={[{
        url: '/admin',
        title: 'Panel'
      }, {
        url: '/admin/customerBilling/list',
        title: 'Cobros a Clientes'
      }, {
        title: 'Ver órden'
      }]}
    >
      <div className="ion-padding">
        {parsedParams?.id ? (<CustomerBillingShow docId={parsedParams?.id} {...{ user, isAllowed, history }} />) : null}
      </div>
    </LayoutAdmin>
  );
};