import { useState, useEffect } from 'react';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import { 
  IonModal, 
  IonButton, 
  IonContent, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonButtons,
  IonReorderGroup,
  IonReorder,
  IonIcon,
  IonItem
} from '@ionic/react';
import { reorderTwo } from 'ionicons/icons';
import RawInputGPS from './RawInputGPS';
import ViewGPS from '../ViewGPS';

const BranchForm = ({ branch, isOpen, onClose, onSave, isEditing }) => {
  const [formData, setFormData] = useState({ id: '', name: '', address: '', gps: null });

  useEffect(() => {
    if (branch) {
      setFormData(branch);
    } else {
      setFormData({ id: nanoid(8), name: '', address: '', gps: null });
    }
  }, [branch]);

  const handleChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleSave = () => {
    formData.id = formData.id || nanoid(8);
    onSave(formData);
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} backdropDismiss={false}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{isEditing ? 'Editar' : 'Agregar'} Sucursal</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>
              Cancelar
            </IonButton>
            <IonButton 
              fill="solid"
              color="secondary"
              strong={true} 
              onClick={handleSave}
            >
              Asignar
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="p-4">
          <div className="space-y-4">
            <div>
              <input
                type="text"
                className="w-full px-2 py-1.5 border border-gray-300 rounded"
                placeholder="Nombre de la sucursal"
                value={formData.name || ''}
                onChange={(e) => handleChange('name', e.target.value)}
              />
            </div>
            
            <div>
              <input
                type="text"
                className="w-full px-2 py-1.5 border border-gray-300 rounded"
                placeholder="Dirección"
                value={formData.address || ''}
                onChange={(e) => handleChange('address', e.target.value)}
              />
            </div>
            
            <div>
              <RawInputGPS
                value={formData.gps}
                onChange={(value) => handleChange('gps', value)}
                classes={{ mapContainer: 'h-32' }}
              />
            </div>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export const ShowRelatedListBranches = ({ value }) => {
  return (
    <div className='mb-2 rounded border border-gray-200'>
      {value?.map((item, index) => (
        <div key={index} className="flex flex-row gap-2 px-3 py-2 border-b border-gray-200">
          {item.gps && (
            <ViewGPS 
              value={item.gps} 
              showGoogleMapsLink={false}
              showZoomControl={false}
              classes={{ 
                mapContainer: 'w-32',
                mapHeight: 'h-24',
                mapRender: 'rounded-md'
              }} 
            />
          )}
          <div>
            <span className="font-semibold">{item.name}</span>
            <div className="text-gray-600">{item.address}</div>
            <div className="text-xs text-gray-400">ID: {item.id}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

const RawInputRelatedListBranches = ({ value, onChange, disabled }) => {
  const [listId] = useState(nanoid());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingBranch, setEditingBranch] = useState(null);
  const items = value || [];

  const handleReorder = (event) => {
    const newItems = event.detail.complete(items);
    onChange(newItems);
  };

  const handleAdd = () => {
    setEditingIndex(null);
    setEditingBranch({ id: nanoid(8), name: '', address: '', gps: null });
    setIsModalOpen(true);
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    const branchToEdit = _.cloneDeep(items[index]);
    setEditingBranch(branchToEdit);
    setIsModalOpen(true);
  };

  const handleDelete = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    onChange(updatedItems);
  };

  const handleSave = (formData) => {
    const updatedItems = [...items];
    if (editingIndex !== null) {
      updatedItems[editingIndex] = formData;
    } else {
      updatedItems.push(formData);
    }
    onChange(updatedItems);
    handleClose();
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setEditingIndex(null);
    setEditingBranch(null);
  };

  return (<>
    <IonReorderGroup 
      disabled={disabled} 
      onIonItemReorder={handleReorder}
      className=''
    >
      {items?.map((item, index) => (
        <IonItem key={index} className="px-3 py-2">
          <IonReorder slot="start">
            <IonIcon icon={reorderTwo} className="text-gray-400" />
          </IonReorder>

          <div className="flex flex-row gap-2 flex-grow">
            {item.gps && (
              <ViewGPS 
                value={item.gps} 
                showGoogleMapsLink={false}
                showZoomControl={false}
                classes={{ 
                  mapContainer: 'w-32',
                  mapHeight: 'h-24',
                  mapRender: 'rounded-md'
                }} 
              />
            )}
            <div>
              <span className="font-semibold">{item.name}</span>
              <div className="text-gray-600">{item.address}</div>
              <div className="text-xs text-gray-400">ID: {item.id}</div>
            </div>
          </div>

          <div slot="end" className="flex space-x-2">
            <IonButton 
              fill="clear"
              size="small"
              disabled={disabled}
              onClick={() => handleEdit(index)}
            >
              Editar
            </IonButton>
            <IonButton
              fill="clear"
              color="danger"
              size="small"
              disabled={disabled}
              onClick={() => handleDelete(index)}
            >
              Eliminar
            </IonButton>
          </div>
        </IonItem>
      ))}
    </IonReorderGroup>

    <div>
      <IonButton
        size="small"
        fill="clear"
        disabled={disabled}
        onClick={handleAdd}
      >
        Agregar sucursal
      </IonButton>
    </div>

    <BranchForm
      branch={editingBranch}
      isOpen={isModalOpen}
      onClose={handleClose}
      onSave={handleSave}
      isEditing={editingIndex !== null}
    />
  </>);
};

export default RawInputRelatedListBranches;
