import _ from 'lodash';
import {useAsyncMemo} from "use-async-memo"
import { useMemo, useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { LayoutAdmin } from "../../components/LayoutAdmin";
import BtnLoading from "../../components/ui/BtnLoading";
import { UserDataCard } from '../user/UserDataCard';
import { IonAlert, IonIcon } from '@ionic/react';
import { 
  bookmarkOutline
} from 'ionicons/icons';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import EntityGetOneDocument from '../../components/EntityGetOneDocument';
import { InstitutionSelector } from './InstitutionSelector';
import Model from '../../libs/ModelClass';

const GridDocs = ({ docs, isAllowed, setDocToApprove, docHightligh, setDocHightligh, setConfirmMode, user, institutionDoc }) => {
  const isSuperAdmin = user?.rolesDoc?.data?.nameSlug === 'superAdmin';
  const [selectedBranch, setSelectedBranch] = useState({});
  const [localDocs, setLocalDocs] = useState(docs);

  useEffect(() => {
    setLocalDocs(docs);
  }, [docs]);

  const handleBranchChange = async (docId, branchId, branches) => {
    const ExtendedModel = Model.extend('usersProfiles');
    const doc = await ExtendedModel.findById(docId);
    doc.data.branchId = branchId || null;
    await doc.save();
    
    // Actualizar el documento en el estado local
    setLocalDocs(prevDocs => 
      prevDocs.map(d => 
        d.id === docId 
          ? {...d, data: {...d.data, branchId: branchId || null}}
          : d
      )
    );
    
    setSelectedBranch(prev => ({...prev, [docId]: branchId || null}));
    
    const branchName = branches.find(b => b.id === branchId)?.name || 'Sin sucursal';
    toast.success(
      branchId 
        ? `${doc.data.name} asignado a sucursal "${branchName}"` 
        : `${doc.data.name} removido de sucursal`,
      { duration: 4000 }
    );
  };

  return (
    <div className={`grid gap-2 grid-cols-2 md:grid-cols-3 xl:grid-cols-4`}>
      {localDocs?.map((doc, index) => (
        <div className="" key={doc.id}>
          <UserDataCard 
            userData={doc.data}
            classes={{ cardContainer: docHightligh === doc ? '!border-gray-500' : '' }}
            showUserLink={isAllowed('usersProfiles', ['list'])}
            underList={(
              <div className="mt-2 border-t border-gray-200">
                {docHightligh === doc ? (
                  <div className="w-auto !mt-0 px-2 pt-1.5 pb-0.5 text-md absolute top-0 right-0 rounded-bl-md text-white bg-gray-500">
                    <IonIcon icon={bookmarkOutline}></IonIcon>
                  </div>
                ) : null}
                {/* Selector de Sucursal para Super Admins */}
                {isSuperAdmin && institutionDoc?.data?.branches?.length ? (
                  <div className="py-2">
                    <select
                      className="w-full p-1 text-sm border rounded"
                      value={doc.data.branchId || ''}
                      onChange={(e) => handleBranchChange(doc.id, e.target.value, institutionDoc.data.branches)}
                    >
                      <option value="">Sin sucursal</option>
                      {institutionDoc.data.branches.map((branch) => (
                        <option key={branch.id} value={branch.id}>
                          {branch.name}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
                <div className={`my-1.5 mr-2 ${!doc.data.institutionApprovedDate && !doc.data.institutionBloquedDate ? 'block' : 'inline-block'}`}>
                  {doc.data.institutionApprovedDate ? (
                    <span className="text-xs px-2.5 py-0.5 rounded-full text-gray-600 bg-gray-50 border border-gray-200">miembro</span>
                  ) : (<>
                    <span className="text-xs px-2.5 py-0.5 rounded-full text-gray-600 bg-gray-50 border border-gray-200">pendiente</span>
                  </>)}
                </div>
                {!doc.data.institutionApprovedDate ? (
                  <BtnLoading
                    label="Aprobar"
                    withLoading className="mr-1.5 px-2 py-0.5 !text-blue-800 hover:scale-105 bg-blue-200 shadow-none text-xs !inline-block"
                    onClickAsync={() => {
                      setDocHightligh(doc);
                      setDocToApprove(doc);
                      setConfirmMode('approve');
                    }} 
                  />
                ) : null}
                {!doc.data.institutionBloquedDate ? (
                  <BtnLoading
                    label="Bloquear"
                    withLoading className="px-2 py-0.5 text-gray-800 hover:scale-105 bg-gray-200 shadow-none text-xs !inline-block"
                    onClickAsync={() => {
                      setDocHightligh(doc);
                      setDocToApprove(doc);
                      setConfirmMode('block');
                    }} 
                  />
                ) : null}
                <div className="">
                  {/* User */}
                  {doc.data.institutionApprovedBy || doc.data.institutionBloquedBy ? (
                    <span className="text-xs text-gray-500 mr-1">
                      {doc.data.institutionApprovedBy ? (
                        "Aprobado por:"
                      ) : null}
                      {doc.data.institutionBloquedBy ? (
                        "Bloqueado por:"
                      ) : null}
                    </span>
                  ) : null}
                  {doc.data.institutionApprovedBy || doc.data.institutionBloquedBy ? (
                    <EntityGetOneDocument
                      entitySlug={'usersProfiles'}
                      docId={doc.data.institutionApprovedBy || doc.data.institutionBloquedBy}>
                      {({ doc, entityDoc }) => (
                        isAllowed('usersProfiles', ['list']) ? (
                          <Link to={`/admin/entity/${entityDoc?.id}/${doc?.id}`} className="text-xs text-gray-500 underline">{doc.data?.name}</Link>
                        ) : (
                          <span className="text-xs text-gray-500">{doc.data?.name}</span>
                        )
                      )}
                    </EntityGetOneDocument>
                  ) : null}
                </div>
                {/* Date */}
                {doc.data.institutionApprovedDate || doc.data.institutionBloquedDate ? (
                  <div className="text-xs text-gray-500">
                    Fecha: {dayjs(doc.data.institutionApprovedDate || doc.data.institutionBloquedDate).format('DD/MM/YYYY HH:mm')}
                  </div>
                ) : null}
              </div>
            )}
          />
        </div>
      ))}
    </div>
  );
};

export const RouteInstitutionMembers = ({ parsedParams, user, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history }) => {
  const [ docToApprove, setDocToApprove ] = useState();
  const [ docHightligh, setDocHightligh ] = useState();
  const [ confirmMode, setConfirmMode ] = useState(false);
  const [ selectedInstitution, setSelectedInstitution ] = useState();
  const [ selectedBranchFilter, setSelectedBranchFilter ] = useState('');
  const institutionId = selectedInstitution?.data?.id || user?.userDoc?.data?.institutionId;
  const showInstitutionSelector = isAllowed('institutions', ['list']);
  const isSuperAdmin = user?.rolesDoc?.data?.nameSlug === 'superAdmin';

  // Obtener el documento de la institución
  const institutionDoc = useAsyncMemo(async () => {
    if (!institutionId) return null;
    const ExtendedModel = Model.extend('institutions');
    const doc = await ExtendedModel.findById(institutionId);
    return doc;
  }, [institutionId]);

  const filteredDocs = useAsyncMemo(async () => {
    const ExtendedModel = Model.extend('usersProfiles');
    let filteredDocs = await ExtendedModel.filterByAttributes({ institutionId });
    filteredDocs = filteredDocs.filter(doc => !doc.data?.deleted);
    
    // Filtrar por sucursal si hay una seleccionada
    if (selectedBranchFilter) {
      if (selectedBranchFilter === 'unassigned') {
        filteredDocs = filteredDocs.filter(doc => !doc.data?.branchId);
      } else {
        filteredDocs = filteredDocs.filter(doc => doc.data?.branchId === selectedBranchFilter);
      }
    }
    
    filteredDocs = _.sortBy(filteredDocs, ['data', 'name']);
    return filteredDocs;
  }, [institutionId, selectedBranchFilter]);

  const docsPending = useMemo(() => {
    return filteredDocs?.filter(doc => !doc.data?.institutionApprovedDate && !doc.data?.institutionBloquedDate);
  }, [filteredDocs, docToApprove]);

  const docsApproved = useMemo(() => {
    return filteredDocs?.filter(doc => doc.data?.institutionApprovedDate && !doc.data?.institutionBloquedDate);
  }, [filteredDocs, docToApprove]);

  const docsBloqued = useMemo(() => {
    return filteredDocs?.filter(doc => doc.data?.institutionBloquedDate);
  }, [filteredDocs, docToApprove]);

  const confirmedApprove = async () => {
    docToApprove.data.institutionApprovedDate = new Date().toISOString();
    docToApprove.data.institutionApprovedBy = user.userDoc.id;
    docToApprove.data.institutionBloquedDate = null;
    docToApprove.data.institutionBloquedBy = null;
    docToApprove.save();
    setDocToApprove(null);
    setConfirmMode(false);
    setDocHightligh(docToApprove);
    toast.success('Usuario aprobado');
  };

  const confirmedBloqued = async () => {
    docToApprove.data.institutionApprovedDate = null;
    docToApprove.data.institutionApprovedBy = null;
    docToApprove.data.institutionBloquedDate = new Date().toISOString();
    docToApprove.data.institutionBloquedBy = user.userDoc.id;
    docToApprove.save();
    setDocToApprove(null);
    setConfirmMode(false);
    setDocHightligh(docToApprove);
    toast('Usuario bloqueado', {icon: '🚫'});
  };

  const gridProps = { 
    isAllowed, 
    setDocToApprove, 
    docHightligh, 
    setDocHightligh, 
    setConfirmMode,
    user,
    institutionDoc // Pasamos el documento de la institución
  };

  return (
    <LayoutAdmin 
      history={history} 
      defaultHref={`/admin`}
      title="Miembros de la Institución"
      breadcrumbs={[{
        url: '/admin',
        title: 'Panel'
      }, {
        title: "Miembros de la Institución"
      }]}
    > 
      <div className="ion-padding pb-24 flex flex-col md:flex-row gap-4">
        {showInstitutionSelector ? (
          <div className="md:w-1/3">
            <InstitutionSelector title="Selecciona Institución" {...{ selectedInstitution, setSelectedInstitution, selectedId: institutionId }} />
          </div>
        ) : null}
        <div className={`space-y-8 ${showInstitutionSelector ? 'md:w-2/3' : ''}`}>
          {/* Selector de Sucursal Global */}
          {isSuperAdmin && institutionDoc?.data?.branches?.length > 0 && (
            <div className="flex items-center gap-2">
              <select
                className="p-2 text-sm border rounded"
                value={selectedBranchFilter}
                onChange={(e) => setSelectedBranchFilter(e.target.value)}
              >
                <option value="">Todas las sucursales</option>
                <option value="unassigned">Sin asignar</option>
                {institutionDoc.data.branches.map((branch) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.name}
                  </option>
                ))}
              </select>
              {selectedBranchFilter && (
                <button 
                  onClick={() => setSelectedBranchFilter('')}
                  className="px-2 py-1 text-sm text-gray-600 hover:text-gray-800"
                >
                  Limpiar filtro
                </button>
              )}
            </div>
          )}

          {docsPending?.length && (
            <div>
              <h2 className="text-lg font-semibold text-brand-red">Revisión</h2>
              <h3 className="mb-2 text-sm font-light text-gray-500">Usuarios pendientes de aprobación</h3>
              <GridDocs docs={docsPending} {...gridProps} />
            </div>
          ) || null}

          {docsApproved?.length && (
            <div>
              <h2 className="text-lg font-semibold text-brand-dark">Miembros</h2>
              <h3 className="mb-2 text-sm font-light text-gray-500">Usuarios aprobados</h3>
              <GridDocs docs={docsApproved} {...gridProps} />
            </div>
          ) || null}

          {docsBloqued?.length && (
            <div>
              <h2 className="text-lg font-semibold text-brand-dark">Bloqueados</h2>
              <h3 className="mb-2 text-sm font-light text-gray-500">Usuarios sin acceso</h3>
              <GridDocs docs={docsBloqued} {...gridProps} />
            </div>
          ) || null}
        </div>
      </div>
      <IonAlert
        header={confirmMode === "approve" ? "Aprobar acceso al usuario" : "Bloquear usuario"}
        isOpen={confirmMode}
        buttons={[
          {
            text: confirmMode === "approve" ? "Aprobar acceso al usuario" : "Bloquear usuario",
            role: 'confirm',
            handler: confirmMode === "approve" ? confirmedApprove : confirmedBloqued,
          },
          {
            text: 'No',
            role: 'cancel',
            handler: () => {
            },
          },
        ]}
        onDidDismiss={() => {
          setDocToApprove(null);
          setConfirmMode(false);
        }}
      ></IonAlert>
    </LayoutAdmin>
  );
};
