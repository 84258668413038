import { Field } from 'react-final-form';
import _ from 'lodash';
import { ShowError } from './formUtils';


const FormFieldCustom = (props) => {
  let {
    name,
    title,
    fieldsRequired = null,
    validate = null,
    // disabled = false,
    showLabel = true,
    showClearBtn = true,
    onChange,
    Render
  } = props;

  return (
    <div className={`pb-3 ${props?.classes?.fieldContainer || ''}`}>
      <Field name={name} validate={validate}>
        {({ input, meta }) => (
          <>
            {showLabel ? (
              <label position="floating" className={props?.classes?.fieldLabel}>
                <span
                  className={`font-semibold mr-3 ${
                    meta.touched && meta.error ? 'text-red-600' : 'text-gray-900'
                  }`}
                >
                  {title}
                  {(meta.touched && meta.error) || (_.includes(fieldsRequired, name) && !input.value)
                    ? '*'
                    : ''}
                </span>
                {showClearBtn && input?.value ? (
                  <button
                    type="button"
                    className="px-1.5 py-0.5 font-regular text-xs bg-gray-100 text-gray-400 rounded-full hover:text-gray-800 inline-block"
                    onClick={() => input?.onChange(undefined)}
                  >
                    X
                  </button>
                ) : ''}
              </label>
            ) : ''}
            {Render ? (
              <Render
                {...props}
                value={input.value}
                className={props?.classes?.fieldInput || ''}
                onChange={(value) => {
                  input.onChange(value); 
                  onChange && onChange(value);
                }}
              />
            ) : null}
            {/* error */}
            <ShowError name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} />
          </>
        )}
      </Field>
    </div>
  );
};

export default FormFieldCustom;