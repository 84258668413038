import { RouteAddCart } from "./RouteAddCart";
import entities from './entities.json';
import _ from 'lodash';
// import { ReadOnlyItemsInBags } from "./ReadOnlyItemsInBags";
import { RouteCartHistory } from "./RouteCartHistory";
import { RouteCartProduction } from "./RouteCartProduction";
import { RouteDeliveryHistory } from "../cartDelivery/RouteDeliveryHistory";
import { RouteDeliveryPlanning } from "../cartDelivery/RouteDeliveryPlanning";
import { RouteDeliveryPendingOrders } from "../cartDelivery/RouteDeliveryPendingOrders";
import { RouteDeliveryShowOrder } from "../cartDelivery/RouteDeliveryShowOrder";
import BtnLoading from "../../components/ui/BtnLoading";
import Model from "../../libs/ModelClass";
import dayjs from "dayjs";
import { ModalUserSelect } from "./ModalUserSelector";
import { useState } from 'react';
import toast from "react-hot-toast";

let { carts } = entities;

/*
  carts: [{
    userId,
    institutionId,
    total,
    itemsInBags: [{
      id,        // id of the item
      withExtra, // sub item of item in cart, example: toppings
      qty,
      price
    }]
  }],
*/

const getCrudSettingsCarts = () => {
  return {
    showBtnAdd: false
  };
};

const cancelAllByDayAndInstitution = async () => {
  const ExtendedModel = Model.extend('carts');
  let filteredDocs = await ExtendedModel.filterByAttributes({
    institutionId: 'PVuh4arU1ardcvCdEsBW',
    daysSelected: { 'in-array': ['2024-03-07'] }
  });
  for (const cartDoc of filteredDocs) {
    for (const itemsInBags of cartDoc.data.itemsInBags) {
      if (itemsInBags.date === '2024-03-07') {
        for (const itemInBag of itemsInBags.bagItems) {
          console.log(itemInBag);
          itemInBag.deleted = true;
        }
        itemsInBags.deleted = true;
        itemsInBags.total = 0;
      }
    }
    await cartDoc.save();
    console.log('Updated cart', cartDoc.id);
  }
};

const listCartOfUser = async () => {
  const UserModel = Model.extend('usersProfiles');
  let userDocs = await UserModel.filterByAttributes({
    email: 'gomescarmelo431@gmail.com'
  });
  console.log('userDocs', userDocs[0]);
  if (userDocs?.length) {
    const ExtendedModel = Model.extend('carts');
    let filteredDocs = await ExtendedModel.filterByAttributes({
      userId: userDocs[0].id
    });
    console.log('filteredDocs', filteredDocs);
  }
};

async function conciliateUserProfiles(docs, correctIndex) {
  if (!docs) return;
  const userIdCorrect = docs[correctIndex].id;
  const CartsModel = Model.extend('carts');
  const CredentialsModel = Model.extend('credentials');

  for (const profileDoc of docs) {
    console.log('profileDoc', profileDoc);
    if (profileDoc.id !== userIdCorrect) {
      // patch carts
      const cartsToUpdate = await CartsModel.where('userId', '==', profileDoc.id);
      if (cartsToUpdate.length > 0) {
        console.log('cartsToUpdate', profileDoc.id, cartsToUpdate);
        for (const cartDoc of cartsToUpdate) {
          cartDoc.data.userId = userIdCorrect;
          await cartDoc.save();
          console.log('cart updated', cartDoc.id);
        }
      } else {
        console.log('nothing to update', profileDoc.id);
      }
      // remove userProfile
      const credentialToRemove = await CredentialsModel.whereOne('profile', '==', profileDoc.id);
      if (credentialToRemove) {
        console.log('credentialToRemove', credentialToRemove);
        await credentialToRemove.deleteFromDisk();
      }
      await profileDoc.deleteFromDisk();
      console.log('profileDoc deleted', profileDoc);
    }
  }
}

const fixDatesOfBags = async () => {
  const ids = [
    '9hN5jNvrkl275S9Yevb9',
    'WJXFgzvWkJJopf4yLXW4',
    '1bfC2LqntbgdJEaPcrXB',
    '2JRJFYb1ZRtj5SGjSiUB',
    'gWhYuNduWGtssm1AoMJa',
    'ztGyRKgMu1kSFaYEKMsx'
  ];
  const CartsModel = Model.extend('carts');
  for (const cartId of ids) {
    const cart = await CartsModel.findById(cartId);
    console.log('\n\n\nbefore', cart);
    let daysSelected = [];
    for (const bagId in cart.data.itemsInBags) {
      let newDate = dayjs(cart.data.itemsInBags[bagId].date).add(1, 'day').format('YYYY-MM-DD');
      console.log(cart.data.itemsInBags[bagId].date, '->', newDate);
      daysSelected.push(newDate);
      cart.data.itemsInBags[bagId].date = newDate;
    }
    cart.data.daysSelected = daysSelected;
    console.log('after', cart);
    await cart.save();
  }
};

const usersList = async () => {
  const UserModel = Model.extend('usersProfiles');
  let userDocs = await UserModel.getAll();
  // filtra los userDoc.data.ci que empiecen por "3600"
  userDocs = userDocs.filter((user) => {
    return _.startsWith(user.data.ci, '3600');
  });
  console.log('userDocs', userDocs);
};

const AdminConfigAfterLinksList = ({ isAllowed, user }) => {
  const [isOpenSelectUserModal, setIsOpenSelectUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // Abre el modal para seleccionar el usuario a conciliar
  const handleConciliateUserProfiles = () => {
    setIsOpenSelectUserModal(true);
  };

  // Se ejecuta cuando se selecciona un usuario en el componente interno (UserSelect)
  const handleUserSelect = (user) => {
    console.log('User selected:', user);
    setSelectedUser(user);
  };

  // Función que se invoca al presionar el botón del modal (onDoAction)
  const handleDoAction = async () => {
    if (!selectedUser) return;
    // Obtiene todos los perfiles de usuario asociados al mismo email
    const UsersModel = Model.extend('usersProfiles');
    let profiles = await UsersModel.filterByAttributes({
      email: selectedUser.data.email
    });
    // Filtra los perfiles que no estén marcados como eliminados
    profiles = profiles.filter(profile => !profile.data.deleted);
    if (profiles.length > 1) {
      // Se asume que el primer perfil (índice 0) es el correcto
      toast.loading('Conciliando perfiles de ' + selectedUser.data.email);
      await conciliateUserProfiles(profiles, 0);
      toast.success('Perfiles de usuario conciliados para el email ' + selectedUser.data.email);
    } else {
      toast.error('No se encontraron perfiles duplicados para el email ' + selectedUser.data.email);
    }
    setSelectedUser(null);
    setIsOpenSelectUserModal(false);
  };

  return (
    <div className="mt-10">
      <BtnLoading
        label="Conciliar cuentas de usuario"
        onClickAsync={handleConciliateUserProfiles}
        className="block mx-auto font-semibold !items-start !justify-start px-3 py-2 w-[220px] !bg-brand-red !text-white shadow-md !no-underline hover:underline hover:scale-105 transition duration-400 ease-in-out"
        colorClass="text-gray-400 hover:text-gray-700"
      />

      <ModalUserSelect
        isOpen={isOpenSelectUserModal}
        onClose={() => {
          setIsOpenSelectUserModal(false);
          setSelectedUser(null);
        }}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        onSelected={handleUserSelect} // Se actualiza el usuario seleccionado
        onDoAction={handleDoAction}   // Se invoca la acción de conciliación
        actionLabel="Conciliar inicio de sesión"
      />
    </div>
  );
};

export default function ({ setPermissions }) {
  return {
    name: 'Cart',
    slug: 'cart',

    permissions: [
      setPermissions({
        slug: 'carts',
        label: 'Carritos',
        type: 'coded',
        actionsToAdd: ['history', 'materialsByDay', 'cancelOldItem']
      }),
      setPermissions({
        slug: 'delivery',
        label: 'Entregas',
        type: 'coded',
        actions: ['history', 'read', 'planning', 'pendingOrders']
      }),
    ],

    entities: {
      carts
    },

    routesAdmin: {
      cart: {
        add: {
          permission: { resource: 'carts', action: 'create' },
          Content: RouteAddCart
        },
        history: {
          permission: { resource: 'carts', action: 'history' },
          Content: RouteCartHistory
        },
        materialsByDay: {
          permission: { resource: 'carts', action: 'materialsByDay' },
          Content: RouteCartProduction
        }
      },
      delivery: {
        history: {
          permission: { resource: 'delivery', action: 'history' },
          Content: RouteDeliveryHistory
        },
        showOrder: {
          permission: { resource: 'delivery', action: 'read' },
          Content: RouteDeliveryShowOrder
        },
        planning: {
          permission: { resource: 'delivery', action: 'planning' },
          Content: RouteDeliveryPlanning
        },
        pendingOrders: {
          permission: { resource: 'delivery', action: 'pendingOrders' },
          Content: RouteDeliveryPendingOrders
        }
      }
    },

    components: {
      main: {
        AdminConfigAfterLinksList: AdminConfigAfterLinksList
      },
      carts: {
        crud: {
          settings: getCrudSettingsCarts
        },
        codedDataTypes: {
          // 'itemsInBags': {
          //   RenderForm: ReadOnlyItemsInBags,
          //   RenderShow: ReadOnlyItemsInBags
          // },
        }
      },
    }
  };
}
