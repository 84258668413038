import { useEffect, useState, useMemo } from "react";
import { LuCupSoda } from "react-icons/lu";
import { PiBowlFood } from "react-icons/pi";
import _ from 'lodash';
import { LayoutAdmin } from "../../components/LayoutAdmin";
import { getImageURL, numberFormat, priceFormat } from "../../libs/utils";
import config from "../../config";
import dayjs from 'dayjs';
import Model from "../../libs/ModelClass";
import { useEntityFullBySlug } from "../entity/Entity";
import EntityFilterMenuDisplay from "../../components/EntityFilterMenuDisplay";
import dataToQueryFormatter from "../../components/Filters/dataToQueryFormatter";
import BadgeLoading from "../../components/ui/BadgeLoading";
import { attachDocsAndGetTotal, extractProductsAndQtys, extractProductsCanceledAndQtys } from "../customerBilling/BillingProductsCard";
import printJS from "print-js";
import BtnLoading from "../../components/ui/BtnLoading";
import { IonItem } from "@ionic/react";
import ActionsDropdown from "../../components/ui/ActionsDropdown";
import {
  filterCircleOutline
} from 'ionicons/icons';
import { IoPrintOutline } from "react-icons/io5";
import { PrintTagsBtn } from "./PrintTagsBtn";
import { ExportInstitutionDropdown } from './ExportInstitutionDropdown';
import Select from 'react-select';
import { useAsyncMemo } from "use-async-memo";

const filterMenuSlug = "crud";
const maxResultsForCustomer = 4;

const MaterialsModel = Model.extend('-materials');
const FormulasModel = Model.extend('-formulas');
const CartsModel = Model.extend(config?.modules?.cart?.cartsEntitySlug);
const ProductsModel = Model.extend(config?.modules?.cart?.cartItemEntitySlug);
const UsersModel = Model.extend('usersProfiles');
const InstitutionsModel = Model.extend('institutions');

const sortFields = [{
  name: 'nombre',
  field: 'userDoc.data.firstName',
  direction: 'asc'
}, {
  name: 'fecha del registro',
  field: 'createdAt',
  direction: 'desc'
}]


const getMap = (user) => {
  if (user?.rolesDoc?.data?.nameSlug === 'superAdmin') {
    return {
      listShowUser: true,
      listShowInstitution: true,
      filtersPatch: (filters) => (filters)
    };
  }
  else if (user?.rolesDoc?.data?.nameSlug === 'mainCustomer') {
    return {
      listShowUser: true,
      listShowInstitution: false,
      filtersPatch: (filters) => ({ 
        ...filters,
        institutionId: user?.userDoc?.data?.institutionId
      })
    };
  }
  else if (user?.rolesDoc?.data?.nameSlug === 'customer') {
    return {
      listShowUser: false,
      listShowInstitution: false,
      limitResults: maxResultsForCustomer,
      filtersPatch: (filters) => ({ 
        ...filters,
        userId: user?.userDoc?.id,
        institutionId: user?.userDoc?.data?.institutionId
      })
    };
  } 
  return {
    listShowUser: false,
    listShowInstitution: false,
    limitResults: false,
    filtersPatch: (filters) => (filters)
  };
};

const usePermissionsMap = (user) => {
  return getMap(user);
};

const ListItemsFiltered = ({ filtersSelected, setFiltersSelected, user, isAllowed, onShow }) => {
  const { filterMenuTaxonomyTypes } = useEntityFullBySlug({ entitySlug: config?.modules?.cart?.cartsEntitySlug, filterMenuSlug });
  const permissionsMap = usePermissionsMap(user);
  const [ institutionsDocs, setInstitutionsDocs ] = useState([]);
  const [ institutionsById, setInstitutionsById ] = useState([]);
  const [ materialsDocs, setMaterialsDocs ] = useState([]);
  const [ formulasDocs, setFormulasDocs ] = useState([]);
  const [ productsDocs, setProductsDocs ] = useState([]);
  const [ materialsSpecs, setMaterialsSpecsByDay ] = useState();
  const [ loading, setLoading ] = useState(false);
  const [ sortFieldItemsByUser, setSortFieldItemsByUser ] = useState(sortFields[0]);

  useEffect(() => {
    filterMenuTaxonomyTypes && fetchFilteredDocs();
  }, [filterMenuTaxonomyTypes, filtersSelected]);

  useEffect(() => {
    if (materialsSpecs?.productsByUser && sortFieldItemsByUser?.field) {
      let productsByUser = _.sortBy(materialsSpecs.productsByUser, sortFieldItemsByUser?.field);
      if (sortFieldItemsByUser?.direction === 'desc') {
        productsByUser = productsByUser.reverse();
      }
      setMaterialsSpecsByDay({
        ...materialsSpecs,
        productsByUser
      });
    }
  }, [sortFieldItemsByUser]);

  const fetchFilteredDocs = async () => {
    if (!filtersSelected?.createdAt) return;
    
    setLoading(true);
    try {
      const daySelected = dayjs(filtersSelected.createdAt).utc().format('YYYY-MM-DD');
      const filterQuery = {
        ...dataToQueryFormatter(permissionsMap.filtersPatch(filtersSelected), filterMenuTaxonomyTypes),
        daysSelected: { 'in-array': [daySelected] }
      };
      delete filterQuery.createdAt;

      // 1. Fetch initial data in parallel
      const [
        cartsData, 
        productsData,
        institutions,
        materials,
        formulas
      ] = await Promise.all([
        CartsModel.filterByAttributes(filterQuery).then(docs => 
          docs.filter(doc => !doc.data?.deleted)
            .sort((a, b) => new Date(b.data.createdAt) - new Date(a.data.createdAt))
        ),
        productsDocs.length ? Promise.resolve(productsDocs) : fetchAllProducts(),
        institutionsDocs.length ? Promise.resolve(institutionsDocs) : fetchAllInstitutions(),
        materialsDocs.length ? Promise.resolve(materialsDocs) : fetchAllMaterials(),
        formulasDocs.length ? Promise.resolve(formulasDocs) : fetchAllFormulas()
      ]);

      // 2. Si hay institución seleccionada, procesar datos específicos
      let enrichedCarts = cartsData;
      if (filtersSelected?.institutionId) {
        const usersData = await UsersModel.filterByAttributes({ institutionId: filtersSelected.institutionId })
          .then(docs => docs.filter(doc => doc.data.deleted !== true));
        
        // Enriquecer carritos con información de usuario
        enrichedCarts = cartsData.map(cart => ({
          ...cart,
          data: {
            ...cart.data,
            userDoc: usersData.find(user => user.id === cart.data.userId)
          }
        }));

        // Procesar datos por usuario
        const itemsInBags = extractProductsAndQtys([daySelected], enrichedCarts);
        attachDocsAndGetTotal(itemsInBags, productsData, usersData);
        
        const productsByUser = _.sortBy(
          itemsInBags.filter(docs => docs.userDoc),
          'userDoc.data.firstName'
        );

        // Actualizar specs con datos de usuario
        setMaterialsSpecsByDay(prevSpecs => ({
          ...prevSpecs,
          productsByUser,
          productsByUserMeals: productsByUser.filter(
            item => !config.modules.cart.drinksItemTypesIds.includes(item?.itemDoc?.data?.itemType)
          ),
          productsByUserDrinks: productsByUser.filter(
            item => config.modules.cart.drinksItemTypesIds.includes(item?.itemDoc?.data?.itemType)
          )
        }));
      }

      // 3. Procesar productos y materiales
      const productsWithQtyAndDocs = extractAndFetchProductsAndQtysFromCarts(
        daySelected, 
        enrichedCarts, 
        productsData, 
        formulas
      );

      // 4. Actualizar specs finales
      setMaterialsSpecsByDay(prevSpecs => ({
        ...prevSpecs,
        totalsByMaterial: calcMaterialsByDay(productsWithQtyAndDocs, materials),
        productsWithQtyAndDocs,
        productsWithQtyAndDocsMeals: productsWithQtyAndDocs.filter(
          item => !config.modules.cart.drinksItemTypesIds.includes(item?.productDoc?.data?.itemType)
        ),
        productsWithQtyAndDocsDrinks: productsWithQtyAndDocs.filter(
          item => config.modules.cart.drinksItemTypesIds.includes(item?.productDoc?.data?.itemType)
        ),
        ...groupByInstitution(daySelected, enrichedCarts, productsData, formulas, institutions)
      }));

    } catch (error) {
      console.error('Error in fetchFilteredDocs:', error);
    } finally {
      setLoading(false);
    }
  };

  const groupByInstitution = (daySelected, carts, products, formulas, institutions) => {
    const cartsByInstitutionId = _.groupBy(carts, 'data.institutionId');
    
    // Inicializamos las estructuras de retorno
    const itemsByInstitutionAndBranch = {};
    const itemsByInstitution = [];

    // Iteramos sobre cada institución
    institutions.forEach(institutionDoc => {
      const institutionId = institutionDoc.data.id;
      const cartsOfInstitution = cartsByInstitutionId[institutionId] || [];
      
      // Inicializamos la estructura para esta institución
      itemsByInstitutionAndBranch[institutionId] = {};

      // Agrupar los carts por branchId del usuario
      const cartsByBranch = _.groupBy(cartsOfInstitution, cart => {
        return cart.data?.userDoc?.data?.branchId || 'noBranch';
      });

      // Calcular items por cada sucursal
      Object.entries(cartsByBranch).forEach(([branchId, cartsOfBranch]) => {
        const itemsQty = extractFromCarts(products, formulas, addItemToListCb({ 
          daySelected, 
          list: cartsOfBranch 
        }));

        const branchDoc = institutionDoc?.data?.branches?.find(b => b.id === branchId);
        
        itemsByInstitutionAndBranch[institutionId][branchId] = {
          itemsQty,
          branchId,
          branchDoc
        };
      });

      // Calcular el total general de la institución
      const itemsQty = extractFromCarts(products, formulas, addItemToListCb({ 
        daySelected, 
        list: cartsOfInstitution 
      }));

      // Solo agregamos la institución si tiene items
      if (itemsQty.length > 0) {
        itemsByInstitution.push({
          itemsQty,
          institutionDoc
        });
      }
    });

    return {
      itemsByInstitution,
      itemsByInstitutionAndBranch
    };
  };

  const extractAndFetchProductsAndQtysFromCarts = (daySelected, carts, products, formulas) => {
    return extractFromCarts(products, formulas, addItemToListCb({ daySelected, list: carts }));
  };

  const addItemToListCb = ({ daySelected, list }) => (addItemToList) => {
    list.forEach((cartDoc) => {
      const bagOfDay = cartDoc.data.itemsInBags.find(bag => bag.date === daySelected);
      bagOfDay.bagItems.forEach((itemInBag) => {
        if (!itemInBag.deleted) {
          addItemToList(itemInBag.id, itemInBag.qty, cartDoc, itemInBag);
          if (itemInBag.withExtra) {
            addItemToList(itemInBag.withExtra, itemInBag.qty, cartDoc, itemInBag);
          }
        }
      });
    });
  };

  const calcMaterialsByDay = async (productsWithQtyAndDocs, materialsDocs) => {
    // get totals by material
    let totalsByMaterial = [];
    const addMaterialToList = (productQty) => ({ material, measure, qty }) => {
      const itemInList = totalsByMaterial.find(itemWithQty => itemWithQty.materialId === material);
      if (itemInList) {
        // aumentar por measure
        const measureInList = itemInList.qtysAndMeasures.find(qtyByMeasure => qtyByMeasure.measure === measure);
        if (measureInList) {
          measureInList.qty += qty * productQty;
        } else {
          itemInList.qtysAndMeasures.push({
            measure,
            qty
          });
        }
      } else {
        totalsByMaterial.push({ 
          materialId: material, 
          qtysAndMeasures: [{
            measure,
            qty: qty * productQty
          }],
          materialDoc: materialsDocs.find(materialDoc => materialDoc.id === material)
        });
      }
    };
    productsWithQtyAndDocs.forEach(({ formulaDoc, qty }) => {
      formulaDoc?.data?.materials?.forEach(addMaterialToList(qty));
    });
    totalsByMaterial = _.sortBy(totalsByMaterial, ['materialDoc.data.name']);
    return totalsByMaterial;
  };

  const extractFromCarts = (products, formulas, cartsExtractor) => {
    const productsIdsWithQty = [];
    const addItemToList = (itemId, qty, cartDoc, bagItem) => {
      const itemInList = productsIdsWithQty.find(itemWithQty => itemWithQty.id === itemId);
      if (itemInList) {
        itemInList.qty += qty;
      } else {
        productsIdsWithQty.push({ 
          id: itemId, 
          qty, 
          cartDoc,
          price: bagItem.price
        });
      }
    };
    cartsExtractor(addItemToList);
    let productsWithQtyAndDocs = productsIdsWithQty.map(({ id, qty, cartDoc, price }) => {
      const map = {
        productId: id,
        qty,
        price,
        productDoc: products.find(product => product.id === id),
        cartDoc,
        userId: cartDoc.data.userId
      };
      if (map.productDoc?.data?.materials) {
        map.formulaDoc = formulas.find(formula => formula.id === map.productDoc.data.materials);
      } else {
        console.log('falta productDoc.data.materials para', map.productDoc);
      }
      return map;
    });
    productsWithQtyAndDocs = _.sortBy(productsWithQtyAndDocs, ['productDoc.data.name']);
    return productsWithQtyAndDocs;
  };
  
  const fetchAllInstitutions = async () => {
    let docs = await InstitutionsModel.getAll();
    docs = docs.filter(doc => doc.data.deleted !== true);
    let docsById = _.groupBy(docs, 'id');
    setInstitutionsById(docsById);
    setInstitutionsDocs(docs);
    return docs;
  };

  const fetchAllProducts = async () => {
    let docs = await ProductsModel.getAll();
    docs = docs.filter(doc => doc.data.deleted !== true);
    setProductsDocs(docs);
    return docs;
  };

  const fetchAllMaterials = async () => {
    let docs = await MaterialsModel.getAll();
    docs = docs.filter(doc => doc.data.deleted !== true);
    setMaterialsDocs(docs);
    return docs;
  };

  const fetchAllFormulas = async () => {
    let docs = await FormulasModel.getAll();
    docs = docs.filter(doc => doc.data.deleted !== true);
    setFormulasDocs(docs);
    return docs;
  };

  const productsByUserOfInstitution = async (daySelected, cartsDocs, products) => {
    if (!filtersSelected?.institutionId) {
      return null;
    }
    let users = await UsersModel.filterByAttributes({ institutionId: filtersSelected?.institutionId });
    users = users.filter(docs => docs.data.deleted !== true);
    let itemsInBags = extractProductsAndQtys([daySelected], cartsDocs);
    attachDocsAndGetTotal(itemsInBags, products, users);
    itemsInBags = itemsInBags.filter(docs => docs.userDoc);
    itemsInBags = _.sortBy(itemsInBags, (itemInBag) => itemInBag.userDoc.data.firstName);
    return itemsInBags;
  };
  
  const productsCanceledByUserOfInstitution = async (daySelected, cartsDocs, products) => {
    if (!filtersSelected?.institutionId) {
      return null;
    }
    let users = await UsersModel.filterByAttributes({ institutionId: filtersSelected?.institutionId });
    users = users.filter(docs => docs.data.deleted !== true);
    let itemsInBags = extractProductsCanceledAndQtys([daySelected], cartsDocs);
    attachDocsAndGetTotal(itemsInBags, products, users, true);
    itemsInBags = itemsInBags.filter(docs => docs.userDoc);
    itemsInBags = _.sortBy(itemsInBags, (itemInBag) => itemInBag.userDoc.data.firstName);
    return itemsInBags;
  };

  const doPrintList = () => {
    printJS({
      printable: 'products-to-print',
      type: 'html',
      targetStyles: '*',
      maxWidth: 1000,
      title: 'Productos por consumidor',
      style: `
        #products-to-print {
          font-size: 10px;
        }
        .print-only {
          visibility: visible;
          display: block;
        }
        table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 0 10px;
        }
        th, td {
          padding: 4px;
        }
        th {
          text-align: left;
          border-bottom: 1px solid #000;
        }
        td {
          vertical-align: top;
        }
      `
    });
  };  

  const TotalsByItem = ({ onSelect, institutionDoc, mealsList, drinksList, title, imgSrc, classes }) => {
    return (<>
      {mealsList?.length || drinksList?.length ? (<>
        <div className="">
          {/* titulo con imagen */}
          {imgSrc ? (
            <div className="flex flex-row items-center gap-4 mb-4">
              <img src={imgSrc} alt={title} className="w-16 h-16 rounded-sm shadow-md" />
              {onSelect ? (
                <a onClick={() => onSelect(institutionDoc)}>
                  <h3 className="text-left font-semibold underline cursor-pointer">{title}</h3>
                </a>
              ) : (
                <a onClick={() => onSelect?.(institutionDoc)}>
                  <h3 className="text-left font-semibold underline cursor-pointer">{title}</h3>
                </a>
              )}
            </div>
          ) : (
            <a><h3 className="mb-4 text-left font-semibold">{title}</h3></a>
          )}
          {/* lista de items */}
          <div>
            {mealsList?.length ? (<div className="px-2 border border-gray-200 rounded-md mb-4">
              <h4 className="text-left font-normal text-base my-2">
                <span className="mr-1">{mealsList.reduce((sum, item) => sum + item.qty, 0)}</span>
                <i className="mr-1 top-0.5 relative inline-block"><PiBowlFood /></i>
                <span className="inline-block">Comidas</span>
              </h4>
              {mealsList?.map(({ productDoc, qty }, index) => (
                <div className={`
                  flex flex-row place-content-between
                  py-2 border-b border-gray-200 last:border-none
                `} key={index}>
                  <span className="text-sm font-regular">{productDoc?.data?.name}</span>
                  <span className="text-sm font-regular">{numberFormat(qty, 3)}</span>
                </div>
              ))}
            </div>) : null}
            {drinksList?.length ? (<div className="px-2 border border-gray-200 rounded-md">
              <h4 className="text-left font-normal text-base my-2">
                <span className="mr-1">{drinksList.reduce((sum, item) => sum + item.qty, 0)}</span>
                <i className="mr-1 relative inline-block"><LuCupSoda /></i>
                <span className="inline-block">Bebidas</span>
              </h4>
              {drinksList?.map(({ productDoc, qty }, index) => (
                <div className={`
                  flex flex-row place-content-between
                  py-2 border-b border-gray-200 last:border-none
                `} key={index}>
                  <span className="text-sm font-regular">{productDoc?.data?.name}</span>
                  <span className="text-sm font-regular">{numberFormat(qty, 3)}</span>
                </div>
              ))}
            </div>) : null}
          </div>
        </div>
      </>) : (
        <div className="text-left">
          No hay pedidos para el día
        </div>
      )}
    </>);
  };

  const TotalsOfMaterials = ({ itemsList, title, classes }) => {
    return (<>
      {itemsList?.length ? (<>
        <div className="mt-12">
          <h3 className="text-left font-semibold mb-4">{title}</h3>
          <div className="px-2 border border-gray-200 rounded-md">
            {itemsList?.map(({ materialId, materialDoc, qtysAndMeasures }, index) => (
              <div className={`
                flex flex-row place-content-between
                py-2 border-b border-gray-200 last:border-none 
              `} key={materialId}>
                <div className="">
                  <span className="text-sm font-regular">{materialDoc.data.name}</span>
                </div>
                <div className={`text-right flex flex-col place-content-between`}>
                  {qtysAndMeasures?.map(({ qty, measure }, index) => (
                    <span key={index} className="text-sm font-regular">{numberFormat(qty, 3)} {measure}</span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </>) : null}
    </>);
  };

  const ItemsByUser = ({ itemsList, title, classes }) => {
    const [branchSelected, setBranchSelected] = useState(null);
    
    // Obtener el institutionDoc usando el ID del filtro
    const institutionDoc = useAsyncMemo(async () => {
      if (!filtersSelected?.institutionId) return null;
      const ExtendedModel = Model.extend('institutions');
      return await ExtendedModel.findById(filtersSelected.institutionId);
    }, [filtersSelected?.institutionId]);

    // Crear las opciones de sucursales desde institutionDoc.data.branches
    const branchOptions = useMemo(() => {
      return institutionDoc?.data?.branches?.map(branch => ({
        value: branch.id,
        label: branch.name
      })) || [];
    }, [institutionDoc]);

    // Filtrar items por sucursal seleccionada
    const filteredItems = useMemo(() => {
      if (!itemsList) return [];
      if (!branchSelected) return itemsList;
      
      return itemsList.filter(item => 
        item?.userDoc?.data?.branchId === branchSelected
      );
    }, [itemsList, branchSelected]);

    const renderBranchSelect = () => {
      if (!branchOptions.length) return null;
      
      return (
        <div className="w-48">
          <Select
            isClearable
            placeholder="Filtrar por sucursal"
            value={branchSelected ? branchOptions.find(opt => opt.value === branchSelected) : null}
            onChange={(option) => setBranchSelected(option?.value || null)}
            options={branchOptions}
            className="text-xs"
            styles={{
              control: (base) => ({
                ...base,
                minHeight: '28px',
                height: '28px'
              }),
              valueContainer: (base) => ({
                ...base,
                height: '28px',
                padding: '0 6px'
              }),
              input: (base) => ({
                ...base,
                margin: '0px',
                padding: '0px'
              }),
              indicatorsContainer: (base) => ({
                ...base,
                height: '28px'
              })
            }}
          />
        </div>
      );
    };

    return (
      <div>
        {filtersSelected?.institutionId ? (<>
          <h3 className={`text-left font-semibold mb-2 ${classes?.title}`}>{title}</h3>
          {filteredItems?.length ? (<>
            <div className="mb-4 flex flex-row place-content-start gap-2">
              <div className="hidden md:block">
                <BtnLoading
                  label="Lista"
                  onClick={doPrintList}
                  icon={<IoPrintOutline className="text-xl" />}
                  withLoading
                  colorClass="text-white"
                  className="px-3 py-1 text-sm !text-black bg-gray-200 shadow-none hover:bg-gray-300"
                />
              </div>
              <div className="hidden md:block">
                <PrintTagsBtn {...{itemsList, institutionsById, date: filtersSelected?.createdAt}} />
              </div>
              {renderBranchSelect()}
              <ActionsDropdown 
                classes={{ 
                  actionsContainer: '!block', 
                  actionsPopover: 'rounded-md overflow-hidden shadow-xl', 
                  ionIcon: '!text-xl' 
                }} 
                ionIcon={filterCircleOutline}
              >
                {sortFields.map((fieldSpect) => (
                  <IonItem 
                    button 
                    key={fieldSpect.field} 
                    size="small" 
                    color={sortFieldItemsByUser === fieldSpect ? 'secondary' : 'medium'} 
                    onClick={() => setSortFieldItemsByUser(fieldSpect)}
                  >
                    {fieldSpect.name}
                  </IonItem>
                ))}
              </ActionsDropdown>
            </div>
            <div className="px-2 py-2 border border-gray-200 rounded-md">
              <table className="text-xs w-full border-separate" style={{ borderSpacing: '0 10px' }} id="products-to-print">
                <tbody className="gap-2">
                  {filteredItems.map(({ price, qty, userDoc, itemDoc, itemExtraDoc, day, createdAt }, index) => (
                    <tr key={index}>
                      <td className="text-right pr-2 align-top">{index + 1}</td>
                      <td className="space-x-2">
                        <span className="font-semibold">
                          {(userDoc.data.firstName || '') + ' ' + (userDoc.data.lastName || '')}
                          {userDoc.data.branchId && branchOptions.length ? (
                            <span className="font-normal"> [ {branchOptions.find(opt => opt.value === userDoc.data.branchId)?.label} ]</span>
                          ) : null}
                        </span>
                        <span>
                          CI: {numberFormat(userDoc.data.ci)}
                        </span>
                        <span>
                          {priceFormat(price * qty)}
                        </span>
                        <span>
                          {dayjs(createdAt).tz(config.timezone).format('DD-MM-YYYY HH:mm')}
                        </span>
                        <br />
                        <span>
                          {qty}
                        </span>
                        <span>
                          {itemDoc?.data?.name}
                        </span>
                        {itemExtraDoc ? (
                          <span>
                            con {itemExtraDoc?.data?.name} como guarnición
                          </span>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>) : (
            <div className="text-left">
              Sin pedidos
            </div>
          )}
        </>) : null}
      </div>
    );
  };

  const goToInstitution = (institutionDoc) => {
    setFiltersSelected({
      ...filtersSelected,
      institutionId: institutionDoc?.id
    });
  }

  const ProductsByInstitution = ({ materialsSpecs, goToInstitution, config }) => {
    const [branchSelected, setBranchSelected] = useState(null);

    // Mover useMemo fuera de cualquier condición
    const branchOptions = useMemo(() => {
      if (!materialsSpecs?.itemsByInstitutionAndBranch) return [];
      
      const allBranches = new Set();
      
      Object.entries(materialsSpecs.itemsByInstitutionAndBranch).forEach(([institutionId, institutionBranches]) => {
        Object.entries(institutionBranches).forEach(([branchId, { branchDoc }]) => {
          if (branchDoc?.id && branchDoc?.name) {
            allBranches.add(JSON.stringify({
              value: branchId,
              label: branchDoc.name
            }));
          }
        });
      });

      return Array.from(allBranches).map(branch => JSON.parse(branch));
    }, [materialsSpecs?.itemsByInstitutionAndBranch]);

    // Verificaciones después de los hooks
    if (!materialsSpecs?.itemsByInstitution) {
      console.log('No hay datos de instituciones');
      return null;
    }

    if (!materialsSpecs.itemsByInstitution?.length) {
      console.log('No hay instituciones con items');
      return null;
    }

    return (
      <div className="space-y-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-2xl text-left font-semibold">Productos por Institución</h3>
          {branchOptions.length > 0 && (
            <div className="w-48">
              <Select
                isClearable
                placeholder="Filtrar por sucursal"
                value={branchSelected ? branchOptions.find(opt => opt.value === branchSelected) : null}
                onChange={(option) => setBranchSelected(option?.value || null)}
                options={branchOptions}
                className="text-xs"
                styles={{
                  control: (base) => ({
                    ...base,
                    minHeight: '28px',
                    height: '28px'
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    height: '28px',
                    padding: '0 6px'
                  }),
                  input: (base) => ({
                    ...base,
                    margin: '0px',
                    padding: '0px'
                  }),
                  indicatorsContainer: (base) => ({
                    ...base,
                    height: '28px'
                  })
                }}
              />
            </div>
          )}
        </div>

        {materialsSpecs.itemsByInstitution.map(({ institutionDoc, itemsQty }, index) => {
          if (!institutionDoc?.data?.id) {
            console.log('Institución sin ID válido');
            return null;
          }

          const institutionBranches = 
            materialsSpecs.itemsByInstitutionAndBranch[institutionDoc.data.id] || {};
          
          let itemsToShow = itemsQty;

          if (branchSelected) {
            const branchData = institutionBranches[branchSelected];
            if (!branchData?.itemsQty?.length) return null;
            itemsToShow = branchData.itemsQty;
          }

          if (!Array.isArray(itemsToShow) || !itemsToShow.length) {
            console.log('No hay items para mostrar en esta institución');
            return null;
          }

          const mealsList = itemsToShow.filter(item => 
            !config.modules.cart.drinksItemTypesIds.includes(item?.productDoc?.data?.itemType)
          );
          const drinksList = itemsToShow.filter(item => 
            config.modules.cart.drinksItemTypesIds.includes(item?.productDoc?.data?.itemType)
          );

          return (
            <div key={index}>
              <TotalsByItem 
                mealsList={mealsList} 
                drinksList={drinksList} 
                onSelect={goToInstitution} 
                institutionDoc={institutionDoc} 
                title={institutionDoc?.data?.name} 
                imgSrc={getImageURL(institutionDoc?.data?.images?.[0], 'xs')} 
              />
            </div>
          );
        })}
      </div>
    );
  };

  const getData = async (institutionId) => {
    const daySelected = dayjs(filtersSelected.createdAt).utc().format('YYYY-MM-DD');
    const filterQuery = {
      ...dataToQueryFormatter(permissionsMap.filtersPatch({ 
        ...filtersSelected,
        institutionId // Usar el ID de la institución seleccionada
      }), filterMenuTaxonomyTypes),
      daysSelected: { 'in-array': [daySelected] }
    };
    delete filterQuery.createdAt;

    // Obtener carritos filtrados
    const cartsDocs = await CartsModel.filterByAttributes(filterQuery);
    const filteredCarts = cartsDocs.filter(doc => !doc.data?.deleted);

    // Obtener usuarios de la institución
    const users = await UsersModel.filterByAttributes({ institutionId })
      .then(docs => docs.filter(doc => !doc.data?.deleted));

    // Obtener productos si no están cargados
    const products = productsDocs.length ? productsDocs : await fetchAllProducts();

    // Procesar items en bolsas
    const itemsInBags = extractProductsAndQtys([daySelected], filteredCarts);
    attachDocsAndGetTotal(itemsInBags, products, users);

    // Filtrar y ordenar items por usuario
    const itemsWithUser = itemsInBags.filter(item => item.userDoc);
    const sortedItems = _.sortBy(itemsWithUser, item => item.userDoc.data.firstName);

    return {
      productsByUserMeals: sortedItems.filter(
        item => !config.modules.cart.drinksItemTypesIds.includes(item?.itemDoc?.data?.itemType)
      ),
      productsByUserDrinks: sortedItems.filter(
        item => config.modules.cart.drinksItemTypesIds.includes(item?.itemDoc?.data?.itemType)
      )
    };
  };

  const getInstitutionsWithData = async () => {
    const daySelected = dayjs(filtersSelected.createdAt).utc().format('YYYY-MM-DD');
    const institutions = institutionsDocs.filter(async (institution) => {
      const { productsByUserMeals, productsByUserDrinks } = await getData(institution.id);
      return (productsByUserMeals?.length > 0 || productsByUserDrinks?.length > 0);
    });
    return institutions;
  };

  return (<>
    {!filtersSelected?.createdAt ? (
      <div className="text-left">
        Seleccione una fecha
      </div>
    ) : (<>
      {loading ? (
        <div className="mx-auto w-24">
          <BadgeLoading size="md" />
        </div>
      ) : (<>
        <div className="space-y-10">
          <div className="flex items-center justify-between">
            <h3 className="text-2xl text-left font-semibold">
              {dayjs(filtersSelected.createdAt).tz(config.timezone).format("dddd, DD / MMMM / YYYY")}
            </h3>
            <ExportInstitutionDropdown 
              institutions={institutionsDocs}
              filtersSelected={filtersSelected}
              getData={getData}
            />
          </div>

          <TotalsByItem mealsList={materialsSpecs?.productsWithQtyAndDocsMeals} drinksList={materialsSpecs?.productsWithQtyAndDocsDrinks} title="Todos los productos" />
          <TotalsOfMaterials itemsList={materialsSpecs?.totalsByMaterial} title="Materias primas del día" />

          <ProductsByInstitution 
            materialsSpecs={materialsSpecs} 
            goToInstitution={goToInstitution} 
            config={config}
          />

          {/* TODO aplicar esta refactorización */}
          {/* básicamente debes de al momento de procesar materialsSpecs?.productsByUser separar ya por bebidas y por comidas según */}
          <ItemsByUser itemsList={materialsSpecs?.productsByUserMeals} title="Platos pedidos por persona" date={filtersSelected.createdAt} />
          <ItemsByUser itemsList={materialsSpecs?.productsByUserDrinks} title="Bebidas pedidas por persona" date={filtersSelected.createdAt} />

          <ItemsByUser itemsList={materialsSpecs?.productsCanceledByUser} title="Platos cancelados por persona" classes={{title: 'text-red-700'}} />
        </div>
      </>)}
    </>)}
  </>);
};

export const RouteCartProduction = ({ parsedParams, user, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history }) => {  
  const [filtersSelected, setFiltersSelected] = useState({});
  const permissionsMap = usePermissionsMap(user);
  
  return (
    <LayoutAdmin 
      history={history} 
      defaultHref={`/admin`}
      title='Platos y Materias primas'
      breadcrumbs={[{
        url: '/admin',
        title: 'Panel'
      }, {
        title: 'Producción'
      }]}
    >
      <div className="ion-padding pb-32 flex flex-col lg:flex-row">
        <div className="lg:w-2/5">
          <EntityFilterMenuDisplay
            entitySlug={config.modules.cart.cartsEntitySlug}
            filterMenuSlug={filterMenuSlug}
            filterData={filtersSelected}
            onFormChange={(formValues) => {
              setFiltersSelected(formValues);
            }}
            onFormClear={() => {
              setFiltersSelected(null);
            }}
            style={'search-collapsible-panel'}
            paramsByFieldSlug={{
              'userId': {
                isShow: permissionsMap?.listShowUser,
                optionsMapper: (docs) => docs.map((option) => {
                  let label = option?.data?.name;
                  if (option?.data?.ci) {
                    label = label + `, CI: ${option?.data?.ci}`;
                  }
                  if (option?.data?.phone) {
                    label = label + `, ${option?.data?.phone}`;
                  }
                  if (option?.data?.email) {
                    label = label + `, ${option?.data?.email}`;
                  }
                  return {
                    value: option?.id,
                    label
                  };
                })
              },
              'institutionId': {
                isShow: permissionsMap?.listShowInstitution,
                optionsMapper: (docs) => docs.map((option) => ({
                  value: option?.id,
                  label: `${option?.data?.name} | ${option?.data?.city || ''}, ${option?.data?.location || ''}`
                }))
              }
            }}
          />
        </div>

        <div className="mt-4 pt-8 lg:pt-0 lg:mt-0 lg:ml-4 lg:w-3/5">
          <ListItemsFiltered {...{filtersSelected, setFiltersSelected, user, isAllowed}} />
        </div>  
      </div>
    </LayoutAdmin>
  );
};
