import { useState, useEffect } from 'react';
import { IonIcon } from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import _ from 'lodash';
import config from '../../config';

export const ExportInstitutionDropdown = ({
  institutions = [],
  filtersSelected,
  getData,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [availableInstitutions, setAvailableInstitutions] = useState([]);

  // Move styles object to component level
  const styles = {
    header: {
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFE6292F' } },
      font: { color: { argb: 'FFFFFFFF' }, bold: true },
      alignment: { horizontal: 'center', vertical: 'middle' }
    },
    subHeader: {
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF413D3D' } },
      font: { color: { argb: 'FFFFFFFF' }, bold: true },
      alignment: { horizontal: 'center', vertical: 'middle' }
    },
    tableHeader: {
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF413D3D' } },
      font: { color: { argb: 'FFFFFFFF' }, bold: true },
      alignment: { horizontal: 'center' }
    },
    numberValue: {
      alignment: { horizontal: 'right' }
    },
    totalGeneral: {
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFE6292F' } },
      font: { color: { argb: 'FFFFFFFF' }, bold: true, size: 12 },
      alignment: { horizontal: 'left', vertical: 'middle' }
    }
  };

  useEffect(() => {
    const loadInstitutions = async () => {
      if (filtersSelected?.institutionId) {
        const institution = institutions.find(inst => inst.id === filtersSelected.institutionId);
        if (institution) {
          const data = await getData(institution.id);
          const hasData = !!(data.productsByUserMeals?.length || data.productsByUserDrinks?.length);
          setAvailableInstitutions(hasData ? [institution] : []);
        } else {
          setAvailableInstitutions([]);
        }
        return;
      }

      const filtered = await Promise.all(
        institutions.map(async (inst) => {
          const data = await getData(inst.id);
          return {
            ...inst,
            hasData: !!(data.productsByUserMeals?.length || data.productsByUserDrinks?.length)
          };
        })
      );
      setAvailableInstitutions(filtered.filter(inst => inst.hasData));
    };

    loadInstitutions();
  }, [institutions, filtersSelected]);

  const processItemsList = (worksheet, items, type, institutionDoc) => {
    let totalCantidad = 0;
    let totalMonto = 0;

    // Check if institution has branches
    const hasBranches = institutionDoc?.data?.branches?.length > 0;

    // Get branches map for easy lookup
    const branchesMap = {};
    if (hasBranches) {
      institutionDoc?.data?.branches?.forEach(branch => {
        branchesMap[branch.id] = branch.name;
      });
    }

    worksheet.addRow([]);
    const sectionTitleRow = worksheet.addRow([type]);
    sectionTitleRow.eachCell(cell => Object.assign(cell, styles.subHeader));
    worksheet.mergeCells(`A${sectionTitleRow.number}:${hasBranches ? 'H' : 'G'}${sectionTitleRow.number}`);
    worksheet.addRow([]);

    // Headers
    const headers = ['Fecha y Hora'];
    if (hasBranches) headers.push('Sucursal');
    headers.push('Usuario', 'Cédula', 'Producto', 'Precio Unit.', 'Cantidad', 'Total');

    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell(cell => Object.assign(cell, styles.tableHeader));

    // Data rows
    items.forEach(item => {
      // Asegurar que los valores sean numéricos
      const precio = Number(item.price || 0);
      const cantidad = Number(item.qty || 0);
      const total = Number((precio * cantidad).toFixed(0)); // Redondear a entero

      totalCantidad += cantidad;
      totalMonto += total;

      const rowData = [
        dayjs(item.createdAt).tz(config.timezone).format('DD/MM/YYYY HH:mm'),
      ];

      if (hasBranches) {
        rowData.push(branchesMap[item.userDoc?.data?.branchId] || '');
      }

      rowData.push(
        (item.userDoc?.data?.firstName || '') + ' ' + (item.userDoc?.data?.lastName || ''),
        item.userDoc?.data?.ci || '',
        item.itemDoc?.data?.name || '',
        precio,  // Valor numérico simple
        cantidad,
        total    // Valor numérico simple
      );

      const row = worksheet.addRow(rowData);

      // Asegurar alineación correcta y formato para valores numéricos
      const precioUnitColIndex = hasBranches ? 6 : 5;
      const cantidadColIndex = hasBranches ? 7 : 6;
      const totalColIndex = hasBranches ? 8 : 7;

      const precioCell = row.getCell(precioUnitColIndex);
      const cantidadCell = row.getCell(cantidadColIndex);
      const totalCell = row.getCell(totalColIndex);

      // Aplicar formato y alineación
      precioCell.numFmt = '"Gs." #"."##0';
      precioCell.alignment = { horizontal: 'right' };

      cantidadCell.alignment = { horizontal: 'right' };

      totalCell.numFmt = '"Gs." #"."##0';
      totalCell.alignment = { horizontal: 'right' };
    });

    // Subtotal
    worksheet.addRow([]);
    const subtotalRowData = ['Subtotal'];
    if (hasBranches) {
      subtotalRowData.push('');
    }
    subtotalRowData.push('', '', '', '', totalCantidad, totalMonto);

    const subtotalRow = worksheet.addRow(subtotalRowData);
    subtotalRow.eachCell((cell, colNumber) => {
      Object.assign(cell, styles.subHeader);

      // Aplicar formato de moneda al total
      const totalColIndex = hasBranches ? 8 : 7;
      if (colNumber === totalColIndex) {
        cell.numFmt = '"Gs." #"."##0';
      }
    });

    return { totalCantidad, totalMonto };
  };

  const doExcelForInstitution = async (institutionDoc) => {
    if (!institutionDoc?.data?.name) return;

    setLoading(true);
    try {
      const { productsByUserMeals, productsByUserDrinks } = await getData(institutionDoc.id);

      // Check if institution has branches early
      const hasBranches = institutionDoc?.data?.branches?.length > 0;

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Consumos');

      // Configuración de columnas dinámica según si hay sucursales
      const baseColumns = [
        { width: 20 },  // A - Fecha y Hora
        { width: 35 },  // B - Usuario
        { width: 15 },  // C - Cédula
        { width: 60 },  // D - Producto (aumentado de 45 a 60)
        { width: 15 },  // E - Precio Unit.
        { width: 15 },  // F - Cantidad
        { width: 15 },  // G - Total
      ];

      if (hasBranches) {
        // Insertar la columna de sucursal después de la fecha
        baseColumns.splice(1, 0, { width: 25 }); // Sucursal
      }

      worksheet.columns = baseColumns;

      // Título del reporte - Primera fila (rojo)
      const titleRow1 = worksheet.addRow([config.projectName]);
      titleRow1.height = 30;
      worksheet.mergeCells(`A1:${hasBranches ? 'H1' : 'G1'}`);
      titleRow1.eachCell(cell => Object.assign(cell, styles.header));

      // Título del reporte - Segunda fila (gris)
      const titleRow2 = worksheet.addRow(['REPORTE DE CONSUMO']);
      titleRow2.height = 30;
      worksheet.mergeCells(`A2:${hasBranches ? 'H2' : 'G2'}`);
      titleRow2.eachCell(cell => Object.assign(cell, styles.subHeader));

      // Título del reporte - Tercera fila (gris)
      const titleRow3 = worksheet.addRow([institutionDoc.data.name]);
      titleRow3.height = 30;
      worksheet.mergeCells(`A3:${hasBranches ? 'H3' : 'G3'}`);
      titleRow3.eachCell(cell => Object.assign(cell, styles.subHeader));

      worksheet.addRow([]); // Espacio en blanco

      // Fecha con más protagonismo
      const dateRow = worksheet.addRow(['FECHA: ' + dayjs(filtersSelected?.createdAt).tz(config.timezone).format('DD-MM-YYYY')]);
      dateRow.height = 25;
      worksheet.mergeCells(`A5:${hasBranches ? 'H5' : 'G5'}`);
      dateRow.eachCell(cell => Object.assign(cell, styles.subHeader));

      // Create a map of branch order for sorting
      const branchOrderMap = {};
      institutionDoc?.data?.branches?.forEach((branch, index) => {
        branchOrderMap[branch.id] = index;
      });

      // Sorting function by branch order
      const sortByBranch = (a, b) => {
        const branchOrderA = branchOrderMap[a.userDoc?.data?.branchId] ?? Number.MAX_SAFE_INTEGER;
        const branchOrderB = branchOrderMap[b.userDoc?.data?.branchId] ?? Number.MAX_SAFE_INTEGER;

        if (branchOrderA === branchOrderB) {
          // If same branch, sort by user name (firstName + lastName)
          const fullNameA = (a.userDoc?.data?.firstName || '') + ' ' + (a.userDoc?.data?.lastName || '');
          const fullNameB = (b.userDoc?.data?.firstName || '') + ' ' + (b.userDoc?.data?.lastName || '');
          return fullNameA.localeCompare(fullNameB);
        }
        return branchOrderA - branchOrderB;
      };

      // Sort both lists
      const sortedMeals = productsByUserMeals?.sort(sortByBranch);
      const sortedDrinks = productsByUserDrinks?.sort(sortByBranch);

      let totalCantidadComidas = 0;
      let totalMontoComidas = 0;
      let totalCantidadBebidas = 0;
      let totalMontoBebidas = 0;

      if (sortedMeals?.length > 0) {
        const { totalCantidad, totalMonto } = processItemsList(worksheet, sortedMeals, 'DETALLE DE COMIDAS', institutionDoc);
        totalCantidadComidas = totalCantidad;
        totalMontoComidas = totalMonto;
      }

      if (sortedDrinks?.length > 0) {
        const { totalCantidad, totalMonto } = processItemsList(worksheet, sortedDrinks, 'DETALLE DE BEBIDAS', institutionDoc);
        totalCantidadBebidas = totalCantidad;
        totalMontoBebidas = totalMonto;
      }

      const cantidadGeneral = totalCantidadComidas + totalCantidadBebidas;
      const totalGeneral = totalMontoComidas + totalMontoBebidas;

      // Resumen general
      worksheet.addRow([]);
      const resumenRow = worksheet.addRow(['RESUMEN GENERAL']);
      resumenRow.eachCell(cell => Object.assign(cell, styles.header));
      worksheet.addRow([]);

      // Sección de comidas (solo si hay)
      if (sortedMeals?.length > 0) {
        const comidaRows = [
          ['Total Comidas:', totalCantidadComidas],
          ['Monto Total:', totalMontoComidas]
        ];
        comidaRows.forEach(rowData => {
          const row = worksheet.addRow(rowData);
          row.eachCell((cell, colNumber) => {
            Object.assign(cell, styles.subHeader);
            if (colNumber === 2) {
              Object.assign(cell, styles.numberValue);
            }
          });
          if (rowData[0] === 'Monto Total:') {
            const cell = row.getCell(2);
            cell.numFmt = '"Gs." #"."##0';
          }
        });
      }

      // Sección de bebidas (solo si hay)
      if (sortedDrinks?.length > 0) {
        worksheet.addRow([]);
        const bebidaRows = [
          ['Total Bebidas:', totalCantidadBebidas],
          ['Monto Total:', totalMontoBebidas]
        ];
        bebidaRows.forEach(rowData => {
          const row = worksheet.addRow(rowData);
          row.eachCell((cell, colNumber) => {
            Object.assign(cell, styles.subHeader);
            if (colNumber === 2) {
              Object.assign(cell, styles.numberValue);
            }
          });
          if (rowData[0] === 'Monto Total:') {
            const cell = row.getCell(2);
            cell.numFmt = '"Gs." #"."##0';
          }
        });
      }

      // Total General (solo si hay tanto comidas como bebidas)
      if (sortedMeals?.length > 0 && sortedDrinks?.length > 0) {
        worksheet.addRow([]);
        const totalGeneralRows = [
          ['TOTAL GENERAL:', cantidadGeneral],
          ['Monto Total:', totalGeneral]
        ];
        totalGeneralRows.forEach((rowData, index) => {
          const row = worksheet.addRow(rowData);
          row.eachCell((cell, colNumber) => {
            Object.assign(cell, styles.totalGeneral);
            if (colNumber === 2) {
              Object.assign(cell, styles.numberValue);
            }
          });
          if (rowData[0] === 'Monto Total:') {
            const cell = row.getCell(2);
            cell.numFmt = '"Gs." #"."##0';
          }
        });
      }

      worksheet.addRow([]);

      // Pie de página con fecha de generación
      const infoRows = [
        ['Generado el:', dayjs().tz(config.timezone).format('DD-MM-YYYY HH:mm')]
      ];
      infoRows.forEach(rowData => {
        const row = worksheet.addRow(rowData);
        row.eachCell(cell => Object.assign(cell, {
          alignment: { horizontal: 'left' }
        }));
      });

      // Guardar archivo
      const fileName = `Consumo ${institutionDoc.data.name} - ${dayjs(filtersSelected?.createdAt).tz(config.timezone).format('DD-MM-YYYY')}.xlsx`;
      await workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
      });

    } catch (error) {
      console.error('Error al generar el archivo Excel:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!institutions?.length) return null;

  return (
    <div className="relative inline-block text-left ml-4">
      <button
        disabled={loading}
        onClick={() => setIsOpen(!isOpen)}
        className={`
          inline-flex justify-center items-center px-4 py-2 text-sm font-medium
          text-gray-700 bg-white border border-gray-300 rounded-md
          ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'}
          focus:outline-none
        `}
      >
        {loading ? 'Exportando...' : 'Exportar por institución'}
        <IonIcon icon={chevronDownOutline} className="ml-2 -mr-1 h-5 w-5" />
      </button>

      {isOpen && !loading && (
        <div className="origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div className="py-1" role="menu">
            {availableInstitutions.map((institutionDoc) => (
              institutionDoc?.data?.name && (
                <button
                  key={institutionDoc.id}
                  className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={async () => {
                    setIsOpen(false);
                    await doExcelForInstitution(institutionDoc);
                  }}
                >
                  {institutionDoc.data.name}
                </button>
              )
            ))}
          </div>
        </div>
      )}
    </div>
  );
};





