import { useEffect, useState } from "react";
import toast from 'react-hot-toast';
import {
  IonBackButton,
  IonBreadcrumb,
  IonBreadcrumbs,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { 
  arrowBack
} from 'ionicons/icons';
import { UserList, UserMenu } from "./MenuOfUserSession";
import { useAuth } from "../modules/user/AuthContext";
import BadgeLoading from "./ui/BadgeLoading";


const AutoKickOut = () => {
  const { logout } = useAuth();
  const [showDiv, setShowDiv] = useState(false);
  const [showDivLogout, setShowDivLogout] = useState(false);

  useEffect(() => {
    // Mostrar el div después de 2 segundos
    const showTimeout = setTimeout(() => {
      setShowDiv(true);
    }, 2000);

    // Mostrar el div después de 3 segundos
    const showTimeoutLogout = setTimeout(() => {
      setShowDivLogout(true);
    }, 3000);

    // Ejecutar logout después de 6 segundos
    const logoutTimeout = setTimeout(() => {
      logout();
      toast.error('Algo salió mal');
      toast.error('Acceda al sistema nuevamente');
    }, 6000);

    return () => {
      clearTimeout(showTimeout);
      clearTimeout(logoutTimeout);
      clearTimeout(showTimeoutLogout);
    };
  }, []);

  return (
    showDiv ? (
      <div className="font-semibold text-black text-center">
        Posible conexión lenta

        {showDivLogout ? (
          <div className="mt-2 font-semibold text-gray-400">
            Se volvera a inicio automáticamente
          </div>
        ) : null}
      </div>
    ) : null
  );
};

export function LayoutAdmin({ 
  title,
  breadcrumbs,
  ToolbarLeft,
  ToolbarRight,
  ToolbarFull,
  defaultHref,
  forceBack = false,
  showGoBack = true,
  showMenu = true,
  showGoToProfile = true,
  showRefresher = true,
  mainSectionClass,
  history,
  className,
  contentScrollY = true,
  children
 }) {
  const { user } = useAuth();

  const handleRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 600);
  };

  const onStartRefresh = (e) => {
    console.log('refreshed');
  };
  
  return (
    <IonPage id="admin">
      {!user?.userDoc?.data ? (
        <div className="pt-24 flex flex-col gap-14 place-content-center content-center items-center">
          <BadgeLoading className="text-brand-red" />
          <AutoKickOut />
        </div>
      ) : (<>
        {/* header */}
        <IonHeader className="z-30">
          <IonToolbar>
            <IonButtons slot="start">
              {showGoBack ? (
                forceBack ? (
                  <IonButton onClick={()=> { history.push(defaultHref); }} >
                    <IonIcon slot="icon-only" icon={arrowBack} />
                  </IonButton>
                ) : (
                  <IonBackButton defaultHref={defaultHref}></IonBackButton>
                )
              ) : null}
              {showMenu ? (
                <IonMenuButton menu="main"></IonMenuButton>
              ) : null}
              {ToolbarLeft ? (
                <ToolbarLeft />
              ) : null}
            </IonButtons>

            <IonTitle>
              <div className="header-title-logo lg:hidden">
                <div className="mx-auto flex flex-row items-center">
                  <img src="/logo-icono-color.svg" className="h-8 md:h-10 mr-1" />
                  <img src="/logo-texto-negro.svg" className="h-5 md:h-6 justify-self-center" />
                </div>
              </div>
            </IonTitle>
            
            <IonButtons slot="end">
              {ToolbarRight ? (
                <ToolbarRight />
              ) : null}
              <UserMenu menuTrigger="user-menu">
                <IonList>    
                  <UserList
                    menuTrigger="user-menu"
                    history={history}
                    triggerMode="button"
                    showCurrentUser={true}
                    showCurrentUserPic={false}
                    showGoToProfile={showGoToProfile}
                  />
                </IonList>
              </UserMenu>
            </IonButtons>
          </IonToolbar>
          {ToolbarFull ? (
            <ToolbarFull />
          ) : null}
        </IonHeader>

        {/* content */}
        <IonContent className={className} scrollY={contentScrollY}>
          {showRefresher ? (
            <IonRefresher slot="fixed" pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={handleRefresh} onIonStart={onStartRefresh}>
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
          ) : null}

          {(title || breadcrumbs) ? (
            <>
            {breadcrumbs?.length ? (
              <IonBreadcrumbs>
                {breadcrumbs?.map(({ url, title }, index) => (
                  <IonBreadcrumb routerLink={url} routerDirection="back" key={index}>{title}</IonBreadcrumb>
                ))}
              </IonBreadcrumbs>
            ) : null}
            {title ? (
              <IonToolbar>
                <IonTitle>{title}</IonTitle>
              </IonToolbar>
            ) : null}
            </>
          ) : null}

          {children}
          
        </IonContent>
      </>)}
    </IonPage>
  );
}